const EmojiSad = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM6.47 9.28C6.18 8.99 6.18 8.51 6.47 8.22C7.76 6.93 9.87 6.93 11.17 8.22C11.46 8.51 11.46 8.99 11.17 9.28C11.02 9.43 10.83 9.5 10.64 9.5C10.45 9.5 10.26 9.43 10.11 9.28C9.4 8.57 8.24 8.57 7.53 9.28C7.24 9.58 6.76 9.58 6.47 9.28ZM15.6 18.08H8.4C7.7 18.08 7.13 17.51 7.13 16.8C7.13 14.11 9.32 11.92 12.01 11.92C14.7 11.92 16.89 14.11 16.89 16.8C16.88 17.5 16.3 18.08 15.6 18.08ZM17.53 9.28C17.24 9.57 16.76 9.57 16.47 9.28C15.76 8.57 14.6 8.57 13.89 9.28C13.74 9.43 13.55 9.5 13.36 9.5C13.17 9.5 12.98 9.43 12.83 9.28C12.54 8.99 12.54 8.51 12.83 8.22C14.12 6.93 16.23 6.93 17.53 8.22C17.82 8.52 17.82 8.99 17.53 9.28Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default EmojiSad
