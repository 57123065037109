import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { BoxArrowRight } from 'react-bootstrap-icons'

import { PROJECT_NAME } from '../../../utils/constants'

function Header({ setIsLoggedIn }) {
  const location = useLocation()

  const onClickLogout = () => {
    localStorage.removeItem('jwtToken')
    setIsLoggedIn()
  }

  return (
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Nav.Link as={Link} to="/admin/users" eventKey="1">
            {PROJECT_NAME}
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              to="/admin/users"
              eventKey="2"
              active={location.pathname.startsWith('/admin/users')}
            >
              Users
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/admin/payments"
              eventKey="3"
              active={location.pathname === '/admin/payments'}
            >
              Payments
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/admin/scripts"
              eventKey="4"
              active={location.pathname === '/admin/scripts'}
            >
              Scripts
            </Nav.Link>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link as={Link} to="/admin/login" onClick={onClickLogout}>
                <BoxArrowRight className="button-icon" /> Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
}

export default Header
