import React from 'react'
import { Image } from 'react-bootstrap'

import { PRICING_ITEMS_UA } from '../../../utils/constants'
import Item from './components/Item'

import style from './index.module.css'

function Pricing() {
  return (
    <div className="container">
      <h2 className="blockTitle mb-3">
        <span>Ціни</span>
      </h2>

      <div className={style.pricing}>
        <div>
          {PRICING_ITEMS_UA.map((itemProps) => (
            <Item key={itemProps.header} {...itemProps} />
          ))}
        </div>

        <div className="aboutUsPrices">
          <div className="paymentsBlock">
            <Image
              src={'/images/visaMastercardLogo.png'}
              alt="Visa/MasterCard"
              className="visaMastercardLogo"
            />
            <Image src={'/images/liqpayLogo.svg'} alt="LiqPay" className="liqpayLogo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing
