const Linkedin = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99995 0.0546875C4.50713 0.0546875 0.0544434 4.50737 0.0544434 10.0002C0.0544434 15.493 4.50713 19.9457 9.99995 19.9457C15.4928 19.9457 19.9455 15.493 19.9455 10.0002C19.9455 4.50737 15.4928 0.0546875 9.99995 0.0546875ZM7.56537 14.1224H5.55141V7.64124H7.56537V14.1224ZM6.54596 6.8456C5.90986 6.8456 5.49857 6.39495 5.49857 5.83758C5.49857 5.26883 5.92229 4.83164 6.57186 4.83164C7.22142 4.83164 7.61924 5.26883 7.63168 5.83758C7.63168 6.39495 7.22142 6.8456 6.54596 6.8456ZM14.9209 14.1224H12.9069V10.5306C12.9069 9.69458 12.6148 9.12685 11.8865 9.12685C11.3302 9.12685 10.9997 9.51121 10.8536 9.88106C10.7997 10.0126 10.7863 10.1991 10.7863 10.3845V14.1214H8.77127V9.70805C8.77127 8.89894 8.74537 8.22243 8.71843 7.64021H10.4682L10.5604 8.54048H10.6008C10.866 8.1178 11.5156 7.49413 12.6024 7.49413C13.9274 7.49413 14.9209 8.38198 14.9209 10.2903V14.1224Z"
        fill="white"
      />
    </svg>
  )
}

export default Linkedin
