import axios from '../axios'

export const verify = async (userId, code) => {
  return await axios.get(`/auth/verify/${userId}/${code}`)
}

export const googleAuth = async (credentials) => {
  return await axios.post('/auth/google/', { token: credentials })
}

export const verifyToken = async (token) => {
  const data = await axios.get(`/auth/token-verify/${token}`)
  return data
}

export const sendPhoneVerificationCode = async (userId, data) => {
  return await axios.post(`/auth/verify/${userId}`, data)
}

export const verifyPhone = async (userId, code) => {
  return await axios.get(`/auth/verify/${userId}/phone/${code}`)
}
