const Star = ({ customClassName }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M20.52 6.94671L22.4 10.7067C22.6533 11.2267 23.3333 11.72 23.9067 11.8267L27.3067 12.3867C29.48 12.7467 29.9867 14.32 28.4267 15.8934L25.7733 18.5467C25.3333 18.9867 25.08 19.8534 25.2267 20.48L25.9867 23.76C26.5867 26.3467 25.2 27.36 22.92 26L19.7333 24.1067C19.16 23.76 18.2 23.76 17.6267 24.1067L14.44 26C12.16 27.3467 10.7733 26.3467 11.3733 23.76L12.1333 20.48C12.2533 19.84 12 18.9734 11.56 18.5334L8.90667 15.88C7.34667 14.32 7.85334 12.7467 10.0267 12.3734L13.4267 11.8134C14 11.72 14.68 11.2134 14.9333 10.6934L16.8133 6.93338C17.84 4.90671 19.4933 4.90671 20.52 6.94671Z"
        fill="#4945FF"
      />
      <path
        d="M10.6666 7.6665H2.66663C2.11996 7.6665 1.66663 7.21317 1.66663 6.6665C1.66663 6.11984 2.11996 5.6665 2.66663 5.6665H10.6666C11.2133 5.6665 11.6666 6.11984 11.6666 6.6665C11.6666 7.21317 11.2133 7.6665 10.6666 7.6665Z"
        fill="#4945FF"
      />
      <path
        d="M6.66663 26.3335H2.66663C2.11996 26.3335 1.66663 25.8802 1.66663 25.3335C1.66663 24.7868 2.11996 24.3335 2.66663 24.3335H6.66663C7.21329 24.3335 7.66663 24.7868 7.66663 25.3335C7.66663 25.8802 7.21329 26.3335 6.66663 26.3335Z"
        fill="#4945FF"
      />
      <path
        d="M3.99996 17H2.66663C2.11996 17 1.66663 16.5467 1.66663 16C1.66663 15.4533 2.11996 15 2.66663 15H3.99996C4.54663 15 4.99996 15.4533 4.99996 16C4.99996 16.5467 4.54663 17 3.99996 17Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default Star
