import React, { useEffect, useState } from 'react'
import { Container, Row, Spinner, Table } from 'react-bootstrap'
import moment from 'moment'
import { getAllPayments } from '../../services/admin/adminService'
import Pagination from '../../components/Pagination'
import { DEFAULT_ITEMS_PER_PAGE } from '../../utils/constants'

function PaymentsList() {
  const [payments, setPayments] = useState([])
  const [paymentsAmount, setPaymentsAmount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getAllPayments(currentPage, DEFAULT_ITEMS_PER_PAGE)
      .then(({ data }) => {
        setPayments(data.payments)
        setPaymentsAmount(data.totalAmount)
        setIsLoading(false)
      })
      .catch((error) => console.log(error))
  }, [currentPage])

  const handlePageChange = (page) => setCurrentPage(page)

  return (
    <>
      {isLoading ? <Spinner animation={'border'} className="form-spinner" /> : null}
      <Container
        fluid="xxl"
        className="mt-5"
        style={isLoading ? { filter: `blur(3px)`, pointerEvents: 'none' } : {}}
      >
        <Row>
          <h1>Payments</h1>
        </Row>
        <Table bordered responsive="sm" className="mt-5">
          <thead scope="row">
            <tr>
              <th>User</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Paid At</th>
              <th>Id</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td>{payment.userId?.email}</td>
                <td>
                  {payment.amount} {payment.currency}
                </td>
                <td>{payment.status}</td>
                <td>
                  {payment.createdAt ? moment(payment.createdAt).format('YYYY-MM-DD HH:mm') : null}
                </td>
                <td>
                  {payment.paidAt
                    ? moment(Date.parse(payment.paidAt)).format('YYYY-MM-DD HH:mm')
                    : null}
                </td>
                <td>{payment._id}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
          totalItems={paymentsAmount}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  )
}

export default PaymentsList
