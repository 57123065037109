import axios from '../../axios'

export const getAllScripts = async () => {
  return axios.get(`/admin/script/all`)
}

export const createScript = async (values) => {
  return axios.post('/admin/script/', values)
}

export const updateScript = async (scriptId, values) => {
  return axios.put(`/admin/script/${scriptId}`, { scriptBody: values })
}

export const getScriptById = async (scriptId) => {
  return axios.get(`/admin/script/${scriptId}`)
}

export const deleteScriptById = async (scriptId) => {
  return axios.delete(`/admin/script/${scriptId}`)
}
