import React from 'react'
import { Image } from 'react-bootstrap'

import style from './ExampleCard.module.css'

function ExampleCard({
  headerImageSrc,
  headerImageAlt,
  headerText,
  title,
  description = '',
  text,
}) {
  return (
    <div className={`${style.block} ${!description && style.offerExample}`}>
      <div className={style.header}>
        <Image src={headerImageSrc} alt={headerImageAlt} className={style.headerIcon} />
        <h3>{headerText}</h3>
      </div>
      {description && (
        <div className={style.tooltipItem} style={style}>
          <div className={style.titleBlock}>
            <p>{title}</p>
          </div>
          <div className={style.tooltip}>{description}</div>
        </div>
      )}
      <p className={`${style.title} ${style.titleHidden}`}>{title}</p>
      <div dangerouslySetInnerHTML={{ __html: text }} className={style.text} />
    </div>
  )
}

export default ExampleCard
