const EmojiHappy = ({ customClassName }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M21.5866 2.6665H10.4133C5.55996 2.6665 2.66663 5.55984 2.66663 10.4132V21.5732C2.66663 26.4398 5.55996 29.3332 10.4133 29.3332H21.5733C26.4266 29.3332 29.32 26.4398 29.32 21.5865V10.4132C29.3333 5.55984 26.44 2.6665 21.5866 2.6665ZM11.3333 8.5065C12.7066 8.5065 13.84 9.6265 13.84 11.0132C13.84 12.3998 12.72 13.5198 11.3333 13.5198C9.94663 13.5198 8.82663 12.3732 8.82663 10.9998C8.82663 9.6265 9.95996 8.5065 11.3333 8.5065ZM16 25.4398C12.4133 25.4398 9.49329 22.5198 9.49329 18.9332C9.49329 17.9998 10.2533 17.2265 11.1866 17.2265H20.7866C21.72 17.2265 22.48 17.9865 22.48 18.9332C22.5066 22.5198 19.5866 25.4398 16 25.4398ZM20.6666 13.4932C19.2933 13.4932 18.16 12.3732 18.16 10.9865C18.16 9.59984 19.28 8.47984 20.6666 8.47984C22.0533 8.47984 23.1733 9.59984 23.1733 10.9865C23.1733 12.3732 22.04 13.4932 20.6666 13.4932Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default EmojiHappy
