import {
  Clock,
  Coffee,
  EmojiHappy,
  EmojiSad,
  Facebook,
  Flash,
  Instagram,
  Linkedin,
  MessageQuestion,
  Star,
  TwentyFour,
} from '../components/Home/SvgIcon'

// Routes
export const ADMIN_ROUTES = {
  LOGIN: 'login',
  USERS: 'users',
  PAYMENTS: 'payments',
  SCRIPTS: 'scripts',
}
export const PUBLIC_ROUTES = {
  CHANGE_PASSWORD: 'change-password',
  VERIFY: 'verify',
  PHONE_VERIFY: 'phone-verify',
  HOME: '',
  GOOGLE_AUTH: 'google-auth',
  GOOGLE_AUTH_SUCCESS: 'google-auth-success',
  PAYMENT: 'payment',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
}
export const STATIC_ROUTES = {
  CHANGE_PASSWORD_SUCCESS: 'change-password-success',
  CHANGE_PASSWORD_FAIL: 'change-password-fail',
  VERIFY_SUCCESS: 'verify-success',
  VERIFY_FAIL: 'verify-fail',
  GOOGLE_AUTH_FAIL: 'google-auth-fail',
  NOT_FOUND: 'not-found',
  USER_BLOCKED: 'user-blocked',
  USER_NOT_VERIFIED: 'user-not-verified',
  USER_ACTIVE: 'user-active',
}

export const STATIC_FIELDS = ['projectInfo']

export const PROJECT_NAME = 'Pinta AI'

export const DEFAULT_ITEMS_PER_PAGE = 10

export const AUTH_TYPE = {
  GOOGLE: 'google',
  EMAIL: 'email',
}

export const USER_STATUS = {
  NON_VERIFIED: 'Non-verified',
  EMAIL_VERIFIED: 'Email Verified',
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
}

export const LOCAL_STORAGE = {
  PHONE_VERIFICATION_DATA: 'phone-verification-data',
}

export const MENU_NAVIGATION_UA = [
  {
    name: 'Головна',
    link: '#home',
  },
  {
    name: 'Переваги',
    link: '#possibilities',
  },
  {
    name: 'Приклади',
    link: '#examples',
  },
  {
    name: 'Труднощі',
    link: '#doYou',
  },
  {
    name: 'Як це працює',
    link: '#howItWorks',
  },
  {
    name: 'Про нас',
    link: '#aboutUs',
  },
]

export const MENU_NAVIGATION = [
  {
    name: 'Home',
    link: '#home',
  },
  {
    name: 'Possibilities',
    link: '#possibilities',
  },
  {
    name: 'Examples',
    link: '#examples',
  },
  {
    name: 'Do you ...?',
    link: '#doYou',
  },
  {
    name: 'How it works',
    link: '#howItWorks',
  },
]

export const FOOTER_NAVIGATION_UA = [
  {
    name: 'Головна',
    link: '#home',
  },
  {
    name: 'Переваги',
    link: '#possibilities',
  },
  {
    name: 'Приклади',
    link: '#doYou',
  },
  {
    name: 'Як це працює',
    link: '#howItWorks',
  },
]

export const FOOTER_NAVIGATION = [
  {
    name: 'Home',
    link: '#home',
  },
  {
    name: 'Possibilities',
    link: '#possibilities',
  },
  {
    name: 'Do you ...?',
    link: '#doYou',
  },
  {
    name: 'How it works',
    link: '#howItWorks',
  },
]

export const FOOTER_SOCIAL = [
  { SocialIcon: Facebook, link: '/facebook' },
  { SocialIcon: Instagram, link: '/instagram' },
  { SocialIcon: Linkedin, link: '/linkedin' },
]

export const HOME_YOU_WILL_ITEMS_UA = [
  {
    Icon: EmojiHappy,
    title: 'Привертати увагу',
    text: 'Привертай увагу клієнтів з великим бюджетом, навіть коли вже є 20-30 претендентів, що змагаються за ту ж саму вакансію.',
  },
  {
    Icon: Star,
    title: 'Бути унікальним',
    text: 'Виділяйся серед інших, надсилаючи персоналізований текст, що відповідатиме потребам клієнта.',
  },
  {
    Icon: Coffee,
    title: 'Більше відпочивати і менше турбуватись',
    text: 'Зменшуй стрес та робоче навантаження, оскільки Pinta.AI візьме на себе нудну роботу та заощадить твій час для більш важливих справ.',
  },
  {
    Icon: Flash,
    title: 'Бути супер продуктивним',
    text: 'Збільшуй продуктивність, надсилаючи листи в 10 разів швидше.',
  },
]

export const HOME_YOU_WILL_ITEMS = [
  {
    Icon: EmojiHappy,
    title: 'attract attention',
    text: 'Capture the attention of high-paying clients, even when there are already 20-30 applicants competing for the same opportunity.',
  },
  {
    Icon: Star,
    title: 'be unique',
    text: 'Stand out from others by sending deeply customized text according to client needs.',
  },
  {
    Icon: Coffee,
    title: 'stress less',
    text: 'Reduce stress and workload, as Pinta.AI will take care of tedious work and save you time for more important things.',
  },
  {
    Icon: Flash,
    title: 'be like a flash',
    text: 'Increase productivity by sending proposals 10X faster.',
  },
]

export const HOME_DO_YOU_ITEMS_UA = [
  {
    Icon: Clock,
    title: 'Витрачаєш час',
    text: 'Витрачаєш занадто багато часу на написання листів, замість того щоб робити те, що тобі подобається? ',
  },
  {
    Icon: MessageQuestion,
    title: 'Ламаєш голову',
    text: 'Відчуваєш складність у створенні унікальних листів для кожного проекту?',
  },
  {
    Icon: TwentyFour,
    title: '24/7 відправляєш листи',
    text: 'Надсилаєш листи щодня, але клієнти просто не дають тобі шансу?',
  },
  {
    Icon: EmojiSad,
    title: 'Відчуваєш несправедливість',
    text: 'Втомився від того, що тебе обходять на користь інших фрілансерів, які менш кваліфіковані (просто пропонують меншу ціну!)?',
  },
]

export const HOME_DO_YOU_ITEMS = [
  {
    Icon: Clock,
    title: 'waste time',
    text: 'Spend too much time on proposals instead of doing what you like?',
  },
  {
    Icon: MessageQuestion,
    title: 'break your head',
    text: 'Feel it difficult to come up with tailored proposals for each project?',
  },
  {
    Icon: TwentyFour,
    title: 'send offers 24/7',
    text: "Send proposals every day but clients just won't give you a chance?",
  },
  {
    Icon: EmojiSad,
    title: 'feel unfair',
    text: 'Feel sick of getting passed over for other, less-qualified freelancers (who just bid less!)?',
  },
]

export const HOME_HOW_IT_WORKS_TITLES_UA = [
  'Натисніть “Генерувати Лист” на сторінці Створення листів.',
  'Натисніть “Моя інформація” для заповнення інформації про себе',
  'Заповніть інформацію про ваші знання та досвід (тільки один раз, ШІ буде використовувати її для наступних разів)',
  'Поверніться на сторінку Створення листів і натисніть ”Генерувати”, трохи почекайте, поки почнеться магія.',
  'Натисніть “Вставити Супровідний Лист“ для того, щоб вставити згенерований текст в поле',
  'Натисніть “Надіслати“ для Створення супроводжувального листа',
]

export const HOME_HOW_IT_WORKS_TITLES = [
  'Click “Generate Proposal” on the Submit proposal page.',
  'Click “My Info” to fill in info about you',
  'Fill in info about your expertise & experience (only once, AI will grab this info to write a proposal)',
  'Go back to Submit proposal page, click “Generate” and wait a bit for the magic to happen.',
  'Click “Paste Cover Letter” to paste generated proposal in text area',
  'Click “Send” to Submit proposal',
]

export const HOME_HOW_IT_WORKS_IMAGES = [
  {
    src: '/images/clickGenerateProposal.png',
    alt: 'Click Generate Proposal',
  },
  {
    src: '/images/clickMyInfo.png',
    alt: 'Click My Info',
  },
  {
    src: '/images/fillInInfo.png',
    alt: 'Fill In Info',
  },
  {
    src: '/images/clickGenerate.png',
    alt: 'Click Generate',
  },
  {
    src: '/images/clickPaste.png',
    alt: 'Click Paste',
  },
  {
    src: '/images/clickSend.png',
    alt: 'Click Send',
  },
]

export const HOME_GENERATION_EXAMPLES_UA = [
  {
    offer: {
      text: `Ми шукаємо талановитого та досвідченого бекенд-розробника з хорошим навиком роботи з API, щоб приєднатися до нашої компанії. Як бекенд-розробник ви відіграватимете важливу роль у проектуванні, розробці та обслуговуванні серверних компонентів наших веб- та мобільних програм.<br />
      Ми націлені на надання високоякісних рішень нашим клієнтам і забезпечення виняткового досвіду користувачів. Як компанія, що швидко розвивається, ми цінуємо інновації, співпрацю та тверду відданість досконалості.<br />
      Переваги:<br />
      1. Як бекенд-розробник, ви матимете можливість працювати над передовими проектами.<br />
2. Ми прагнемо до професійного росту та розвитку наших співробітників.<br />
3. Ми цінуємо внесок наших працівників і пропонуємо конкурентоспроможний пакет винагороди, який відображає їхні навички та досвід.<br />`,
    },
    proposal: {
      title: 'Простий скрипт для агентства',
      description: 'Простий скрипт для агентства',
      text: `🚀 Завдяки нашому досвіду в інтеграції API 🔗 ми можемо надати вам найкращі поради та рішення для автоматичного розповсюдження ваших пропозицій про роботу.<br />
      💼 Як бекенд-розробник у нашій компанії ви матимете можливість працювати над передовими проектами та займатися своїм професійним ростом і розвитком, а ми пропонуємо конкурентоспроможний компенсаційний пакет, який відображає ваші навички та досвід.<br />
      💡 Ми націлені на надання високоякісних рішень нашим клієнтам і забезпечення виняткового досвіду користувачів. Як компанія, що швидко розвивається, ми цінуємо інновації, співпрацю та тверду відданість досконалості.<br />
      🗓️ Чи є у вас конкретні терміни для цього проекту? Повідомте нас, і ми працюватимемо, щоб задовольнити ваші вимоги.`,
    },
  },
  {
    offer: {
      text: "Привіт! Мені потрібно розробити розширення для Google Chrome, пов'язане з особистою продуктивністю (управління списками справ і т. д.), зі списком функцій, які я задокументував. Я шукаю експерта зі складання макетів, який зможе перетворити список функцій в макет майбутнього Chrome розширення. Я можу надіслати вам список функцій, щоб ви могли створити початкові макети (перегляньте схожі рішення для управління списками справ, щоб знайти натхнення), або я готовий попрацювати на зізвоні, щоб ви могли отримати живий фідбек.",
    },
    proposal: {
      title: 'Розширений скрипт для агентства',
      description: 'Розширений скрипт для агентства',
      text: 'Ми ProductCrafters, українська компанія з розробки програмного забезпечення з більш ніж 8-річним досвідом роботи, що спеціалізується на проектах React/React Native. Середня оцінка клієнтів нашого майбутнього розширення Chrome на Clutch становить 5,0. Ми запустили успішний онлайн магазин нерухомості та додаток для консультацій з краси, а також розробили нову платформу мобільного ринку. У нас є досвід керування списками справ з високонавантаженими програмами, такими як RL Garage для Rocket League із 2,8 мільйонами користувачів на iOS та Android. Наші клієнти отримали вигоду від наших послуг: клієнт із Великобританії залучив 150 тис. дол. США лише на основі дизайну, а клієнт із SF подвоїв участь протягом 3 місяців і продовжує збільшувати кількість членів команди. Давайте почнемо разом з вами сьогодні працювати над вашим додатком для електронної комерції в React Native. Сплануймо дзвінок!',
    },
  },
  {
    offer: {
      text: 'Я висококваліфікований React-розробник із шестирічним досвідом створення веб-додатків. Моя пристрасть до програмування та моє знання React зробили мене затребуваним професіоналом у технологічній індустрії. Я живу в Сан-Франциско, де брав активну участь у різних проектах і зробив значний внесок в успіх моїх клієнтів і команд. Я отримав ступінь бакалавра комп’ютерних наук у Стенфордському університеті. Під час навчання я зацікавився веб-розробкою та фронтенд-технологіями. Я брав активну участь у конкурсах програмування та співпрацював з однокурсниками над інноваційними проектами.',
    },
    proposal: {
      title: 'Простий скрипт для фрілансера',
      description: 'Простий скрипт для фрілансера',
      text: 'Шановний менеджер з найму! Я радий подати заявку на посаду фронтенд розробника для редизайну веб-сайту вашого клієнта. Як розробник React із шістьма роками досвіду створення веб-додатків, я вважаю, що маю необхідні навички та знання, необхідні для успіху проекту. Як ви просили, я переглянув вкладений документ і я розумію, що вам потрібен хтось, хто б узяв на себе відповідальність і самостійно працював над переробкою всієї фронтенд частини веб-сайту. Я впевнений, що мої навички та досвід роблять мене ідеальним кандидатом на цю посаду, і я готовий наполегливо працювати, щоб втілити бачення вашого клієнта в життя. Це дало мені хороший досвід у веб-розробці. Будь ласка, прийміть мою пропозицію щодо вашої посади фронтенд розробника. Будь ласка, дайте мені знати, якщо у вас виникнуть запитання або вам потрібна додаткова інформація. Дякую за ваш час і увагу.',
    },
  },
  {
    offer: {
      text: 'Я досвідчений розробник Node.js із дворічним досвідом створення ефективних серверних рішень. У мене велика пристрасть до бекенд-розробки, і я відточив свої навички роботи з Node.js, щоб надавати високоякісні рішення. Я живу в Сіетлі, центрі технологій та інновацій, де я брав участь у різних проектах і продемонстрував свій досвід роботи з Node.js.',
    },
    proposal: {
      title: 'Розширений скрипт для фрілансера',
      description: 'Розширений скрипт для фрілансера',
      text: `1️⃣ Експертиза: 💻 Розробка Node.js<br />
      2️⃣ Переваги: 🚀 Завдяки моєму досвіду та знанням у Node.js я можу надати швидкі та ефективні рішення для усунення помилок у вашій існуючій програмі за допомогою mongoose та handlebars.<br />
      3️⃣ Відповідна інформація: у мене є дворічний досвід у розробці бекенда за допомогою Node.js. Протягом моєї кар’єри я постійно розвивав свої навички та знання, щоб бути в курсі останніх технологій і тенденцій.<br />
      4️⃣ Хронологія: я розумію терміновість виправлення помилок у існуючому проекті, і я можу негайно працювати над цим проектом. Запевняю, що виконаю роботу якісно в обумовлений термін.`,
    },
  },
]

export const HOME_GENERATION_EXAMPLES = [
  {
    offer: {
      text: `We are seeking a talented and experienced Backend Developer with a strong proficiency in working with APIs to join our company. As a Backend Developer, you will play a critical role in designing, developing, and maintaining the server-side components of our web and mobile applications.<br />
      We are dedicated to delivering high-quality solutions to our clients and providing exceptional user experiences. As a rapidly growing company, we value innovation, collaboration, and a strong commitment to excellence.<br />
      Benefits:<br />
      1. As a Backend Developer, you will have the opportunity to work on cutting-edge projects.<br />
2. We are committed to the professional growth and development of our employees.<br />
3. We value our employees' contributions and offer a competitive compensation package that reflects their skills and experience.<br />`,
    },
    proposal: {
      title: 'Agency Simple Script',
      description: 'Text example',
      text: `🚀 Based on our expertise in API integration 🔗, we can provide you with the best advice and solution for the automatic distribution of your job offers to indeed.<br />
💼 As a Backend Developer in our company, you will have the opportunity to work on cutting-edge projects and commit to your professional growth and development, while we offer a competitive compensation package that reflects your skills and experience.<br />
💡 We are dedicated to delivering high-quality solutions to our clients and providing exceptional user experiences. As a rapidly growing company, we value innovation, collaboration, and a strong commitment to excellence.<br />
🗓️ Do you have a particular timeline for this project? Let us know and we'll work to meet your requirements.`,
    },
  },
  {
    offer: {
      text: 'Hi,i have a chrome extension to be developed about personal productivity (managing to do lists, ...) with a list of features that i documented.i am looking for a expert in wireframing to go from the features to develop/design wireframes of the <span>future chrome extension.</span>I can send you the features list so you can do initial wireframes (check similar solutions of <span>to do lists management</span> to get inspired), or i am ready to work on it with you on live calls so you can have live feedback.',
    },
    proposal: {
      title: 'Agency Advanced Script',
      description: 'Text example',
      text: "We are ProductCrafters, a Ukrainian boutique software development company with over 8 years of experience specializing in React/React Native projects. Our <span>future chrome extension</span> average client review on Clutch is 5.0. We have launched a successful real-estate marketplace and a beauty advisory app, and developed a new mobile market platform. We have experience <span>to do lists management</span> with high-load apps, such as RL Garage for Rocket League with 2.8 million users on iOS and Android. Our clients have benefited from our services, with a UK-based client raising $150k based on design only and an SF-based client doubling involvement within 3 months and continuing to hire. Let's start working on your ecommerce application in React Native development with us today. Schedule a call!",
    },
  },
  {
    offer: {
      text: "I'm a highly skilled React developer with six years of experience in building web applications. My passion for coding and my proficiency in React have made me a sought-after professional in the tech industry. I'm based in San Francisco, where I have been actively involved in various projects and have contributed significantly to the success of my clients and teams. I've earned my Bachelor's degree in Computer Science from Stanford University. During my studies, I developed a keen interest in web development and frontend technologies. I actively participated in coding competitions and collaborated with fellow students on innovative projects.",
    },
    proposal: {
      title: 'Freelancer Simple Script',
      description: 'Text example',
      text: `Dear Hiring Manager,I'm excited to apply for the front-end developer position for your client's app and website dashboard redesign. As a highly skilled React developer with six years of experience in building web applications, I believe I have the necessary skills and expertise required for the project's success. I understand that you require someone to take charge and work independently on redesigning the entire front end of the driver and user app and admin website. I'm confident that my skills and expertise make me the ideal candidate for this position. Please accept my proposal for your front-end developer position. I'm excited about the opportunity to bring my expertise to this project and work with a dynamic team to deliver an outstanding result. Please let me know if you have any questions or require further information from me.Thank you for your time and consideration.`,
    },
  },
  {
    offer: {
      text: "I'm a skilled Node.js developer with two years of experience in building scalable and efficient server-side applications. I have a strong passion for backend development and have honed my skills in working with Node.js to deliver high-quality solutions. I am based in Seattle, a hub for technology and innovation, where I have contributed to various projects and demonstrated my expertise in Node.js.",
    },
    proposal: {
      title: 'Freelancer Advanced Script',
      description: 'Text example',
      text: `1️⃣ Expertise: 💻 Node.js development<br />
      2️⃣ Benefits: 🚀 With my experience and expertise in Node.js, I can provide quick and efficient solutions for the bugs in your existing application using mongoose and handlebars.<br />
      3️⃣ Relevant info: I have two years of experience in backend development using Node.js. During my career, I have continuously developed my skills and knowledge to stay up-to-date with the latest technologies and trends. <br />
      4️⃣ Timeline: I understand the urgency of fixing bugs in an existing project, and I can work on this project immediately. I assure you that I will deliver quality work within the given deadline.`,
    },
  },
]

export const LANGUAGE_UA = 'ua'

export const TERMS_AND_CONDITIONS = (
  <>
    <strong>Last Updated: 27 - 04 - 2023</strong>
    <br />
    <br />
    <strong>Introduction</strong> <br />
    <br />
    This Privacy Policy governs the manner in which "Pinta AI" (the "Extension"), a Google Chrome
    extension developed by ProductCrafters ("us", "we", or "our"), collects, uses, maintains, and
    discloses information collected from users (each, a "User") of the Extension. This Privacy
    Policy applies to the Extension and all products and services offered by ProductCrafters related
    to the Extension.
    <br />
    <br /> By using the Extension, you agree to the collection and use of information in accordance
    with this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the
    Extension.
    <br />
    <br /> Information Collection and Use We may collect and process the following types of
    information from Users:
    <br />
    <br /> A. Personal Data We do not collect personally identifiable information, such as your
    name, address, phone number, or email address.
    <br />
    <br /> B. Non-Personal Data We may collect non-personal identification information about Users,
    such as browser name, type of computer, and technical information about Users' means of
    connection to the Extension, including the operating system, and other similar information.
    <br />
    <br /> C. AI-related Data Our Extension uses Artificial Intelligence (AI) technologies to
    provide its services. As a result, the Extension may collect and process data, such as text
    input or browsing history, to improve the AI algorithms and offer personalized suggestions or
    recommendations. This data is anonymized and cannot be linked back to individual Users.
    <br />
    <br />
    <strong>Data Usage</strong>
    <br />
    We use the collected information for the following purposes:
    <br />
    - To provide and maintain the Extension and its features
    <br />
    - To improve the Extension's performance and functionality
    <br />
    - To personalize the User experience
    <br />
    - To analyze the usage of the Extension
    <br />
    - To detect, prevent, and address technical issues
    <br />
    <br />
    <strong>Data Storage and Security</strong>
    <br />
    We adopt appropriate data collection, storage, and processing practices, and security measures
    to protect your information from unauthorized access, alteration, disclosure, or destruction.{' '}
    <br />
    <br />
    <strong>Third-Party Websites and Services</strong>
    <br />
    Users may find advertising or other content in the Extension that links to the websites and
    services of our partners, suppliers, advertisers, sponsors, licensors, or other third parties.
    We do not control the content or links that appear on these websites and are not responsible for
    the practices employed by websites linked to or from our Extension. Browsing and interaction on
    any other website, including websites that have a link to or from our Extension, is subject to
    that website's own terms and policies. <br />
    <br />
    <strong>Changes to This Privacy Policy</strong>
    <br />
    ProductCrafters has the discretion to update this Privacy Policy at any time. When we do, we
    will revise the "Last Updated" date at the top of this page. We encourage Users to frequently
    check this page for any changes to stay informed about how we are helping to protect the
    personal information we collect. By continuing to use the Extension after changes are made to
    this Privacy Policy, you acknowledge and agree to the updated Privacy Policy. <br />
    <br />
    <strong>Contact Us</strong>
    <br />
    If you have any questions about this Privacy Policy or our data handling practices, please
    contact us at: <br />
    <br />
    ProductCrafters
    <br /> Khmelnytskyi, Trembovetskoi 23 street,
    <br /> Email: oleg@productcrafters.io
    <br />
    <br />
    <strong>This document was last updated on 27 - 04 - 2023</strong>
  </>
)

export const TERMS_AND_CONDITIONS_UA = (
  <>
    <strong>Останнє оновлення: 27 - 04 - 2023</strong>
    <br />
    <br />
    <strong>Вступ</strong> <br />
    <br />
    Ця Політика конфіденційності регулює спосіб, у який «Pinta AI» («Розширення»), розширення Google
    Chrome, розроблене ФОП Калита Олег Дмитрович («нас», «ми» або «наш»), збирає, використовує,
    зберігає та розкриває інформацію зібрану від користувачів (кожен «Користувач») розширення. Ця
    Політика конфіденційності застосовується до розширення та всіх продуктів і послуг, які пропонує
    ProductCrafters і пов’язані з розширенням.
    <br />
    <br /> Використовуючи розширення, ви погоджуєтеся на збір і використання інформації відповідно
    до цієї Політики конфіденційності. Якщо ви не згодні з цією Політикою конфіденційності, будь
    ласка, не використовуйте розширення.
    <br />
    <br /> Збір і використання інформації Ми можемо збирати та обробляти такі типи інформації від
    користувачів:
    <br />
    <br /> А. Особисті дані. Ми не збираємо особисту інформацію, таку як ваше ім’я, адреса, номер
    телефону або адреса електронної пошти.
    <br />
    <br /> Б. Неособисті дані Ми можемо збирати неособисту ідентифікаційну інформацію про
    користувачів, таку як ім’я браузера, тип комп’ютера та технічну інформацію про засоби
    підключення користувачів до розширення, включаючи операційну систему та іншу подібну інформацію.
    <br />
    <br /> C. Дані, пов’язані зі штучним інтелектом. Наше розширення використовує технології
    штучного інтелекту (AI) для надання своїх послуг. У результаті розширення може збирати й
    обробляти дані, як-от введення тексту чи історію веб-перегляду, для покращення алгоритмів ШІ та
    надання персоналізованих супроводжувальних листів чи рекомендацій. Ці дані анонімні та не можуть
    бути пов’язані з окремими Користувачами.
    <br />
    <br />
    <strong>Використання даних</strong>
    <br />
    Ми використовуємо зібрану інформацію для наступних цілей:
    <br />
    - Щоб надавати та підтримувати Розширення та його функції;
    <br />
    - Щоб покращити продуктивність і функціональність розширення;
    <br />
    - Щоб персоналізувати досвід користувача;
    <br />
    - Щоб проаналізувати використання розширення;
    <br />
    - Для виявлення, запобігання та вирішення технічних проблем.
    <br />
    <br />
    <strong>Спільне використання та розкриття даних</strong>
    <br />
    Ми не продаємо, не обмінюємо та не здаємо в оренду особисту ідентифікаційну інформацію
    користувачів третім особам. Ми можемо надавати зведену демографічну інформацію, не пов’язану з
    будь-якою особистою ідентифікаційною інформацією, нашим діловим партнерам, афілійованим особам і
    рекламодавцям для зазначених цілей
    <br />
    <br />
    <strong>Зберігання даних і безпека</strong>
    <br />
    Ми використовуємо відповідні методи збору, зберігання й обробки даних, а також заходи безпеки,
    щоб захистити вашу інформацію від несанкціонованого доступу, зміни, розголошення чи знищення.
    <br />
    <br />
    <strong>Веб-сайти та сервіси третіх сторін</strong>
    <br />
    Користувачі можуть знаходити рекламу чи інший вміст у Розширенні, який посилається на веб-сайти
    та служби наших партнерів, постачальників, рекламодавців, спонсорів, ліцензіарів або інших
    третіх сторін. Ми не контролюємо вміст або посилання, які з’являються на цих веб-сайтах, і не
    несемо відповідальності за методи, які застосовуються веб-сайтами, пов’язаними з нашим
    розширенням або з нього. Перегляд і взаємодія на будь-якому іншому веб-сайті, включаючи
    веб-сайти, які мають посилання на наше розширення або з нього, регулюються власними умовами та
    політикою цього веб-сайту.
    <br />
    <br />
    <strong>Зміни в цій Політиці конфіденційності</strong>
    <br />
    ФОП Калита О.Г. має право оновлювати цю Політику конфіденційності в будь-який час. Коли ми це
    зробимо, ми змінимо дату останнього оновлення у верхній частині цієї сторінки. Ми заохочуємо
    користувачів часто перевіряти цю сторінку на наявність будь-яких змін, щоб бути в курсі того, як
    ми допомагаємо захищати особисту інформацію, яку збираємо. Продовжуючи використовувати
    Розширення після внесення змін до цієї Політики конфіденційності, ви визнаєте та погоджуєтеся з
    оновленою Політикою конфіденційності.
    <br />
    <br />
    <strong>Зв'язатись з нами</strong>
    <br />
    Якщо у вас виникли запитання щодо цієї Політики конфіденційності або наших методів обробки
    даних, зв’яжіться з нами за адресою:
    <br />
    <br />
    ФОП Калита О.Г.
    <br />
    Україна, м. Хмельницький, вул. М.Трембовецької 23,
    <br /> Пошта: oleg.kalyta@gmail.com
    <br />
    <br />
    <strong>Цей документ востаннє оновлено 27 квітня 2023 року</strong>
  </>
)

export const PRICING_ITEMS_UA = [
  {
    header: {
      text: 'Пробний період',
      color: 'var(--footer-bg)',
    },
    price: 'Безкоштовно',
    items: [
      '10 генерацій на аккаунт',
      'Необмежений доступ до розширення',
      'Можливість використання скриптів',
    ],
  },
  {
    header: {
      text: 'Платний',
      color: 'var(--dodger-blue)',
    },
    price: '10$',
    items: [
      '100 генерацій за кожну покупку',
      'Необмежений доступ до розширення',
      'Можливість використання скриптів',
    ],
  },
]
