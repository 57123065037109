import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, Container, InputGroup, Row, Spinner, Table } from 'react-bootstrap'
import { PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons'
import DeleteConfirmModal from '../../../components/admin/Script/DeleteConfirm'
import UpsertScriptModal from '../../../components/admin/Script/Upsert'
import { getAllScripts } from '../../../services/admin/scriptService'
import './index.css'

function ScriptsList() {
  const [scripts, setScripts] = useState([])
  const [scriptUpdated, setScriptUpdated] = useState(false)
  const [modal, setModal] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getAllScripts()
      .then(({ data }) => {
        setScripts(data)
        setScriptUpdated(false)
        setIsLoading(false)
      })
      .catch((error) => console.log(error))
  }, [scriptUpdated])

  const showCreateModal = () => {
    setModal(<UpsertScriptModal setModal={setModal} setScriptUpdated={setScriptUpdated} />)
  }

  const showUpdateModal = (scriptId) => {
    setModal(
      <UpsertScriptModal
        setModal={setModal}
        setScriptUpdated={setScriptUpdated}
        scriptId={scriptId}
      />
    )
  }

  const showDeleteModal = (scriptId) => {
    setModal(
      <DeleteConfirmModal
        setModal={setModal}
        setScriptUpdated={setScriptUpdated}
        scriptId={scriptId}
        setIsLoading={setIsLoading}
      />
    )
  }

  return (
    <>
      {isLoading ? <Spinner animation={'border'} className="form-spinner" /> : null}
      {modal}
      <Container
        fluid="xxl"
        className="mt-5"
        style={modal || isLoading ? { filter: `blur(3px)`, pointerEvents: 'none' } : {}}
      >
        <Row>
          <h1>Scripts</h1>
        </Row>
        <Row>
          <Col>
            <Button
              className="float-end"
              style={{ marginBottom: '5px' }}
              variant="success"
              onClick={showCreateModal}
            >
              <PlusCircle className="button-icon" /> Create
            </Button>
          </Col>
        </Row>
        <Table bordered responsive="sm">
          <thead scope="row">
            <tr>
              <th>Title</th>
              <th>Fields</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {scripts.map((script, index) => (
              <tr key={index}>
                <td>{script.title}</td>
                <td>
                  <div className="field-group">
                    {script.fields.map((field) => (
                      <Badge key={field._id}>
                        <InputGroup>
                          <div className="field-group__item">{field.name}</div>
                        </InputGroup>
                      </Badge>
                    ))}
                  </div>
                </td>
                <td className="buttons-column">
                  <div className="table-buttons">
                    <Button variant="primary" onClick={() => showUpdateModal(script._id)}>
                      <PencilSquare className="button-icon" /> Edit
                    </Button>
                    <Button variant="danger" onClick={() => showDeleteModal(script._id)}>
                      <Trash className="button-icon" /> Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

export default ScriptsList
