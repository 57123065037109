import React from 'react'
import PropTypes from 'prop-types'

import { LANGUAGE_UA, TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_UA } from '../../utils/constants'

import style from './TermsAndConditions.module.css'

function TermsAndConditions({ lang }) {
  return (
    <section className="overflow-clip">
      <div className="container">
        <div className={style.block}>
          {lang === LANGUAGE_UA ? (
            <h2 className="blockTitle mb-3">
              Політика конфіденційності <span>"Pinta AI"</span>
            </h2>
          ) : (
            <h2 className="blockTitle mb-3">
              Privacy Policy for <span>"Pinta AI"</span>
            </h2>
          )}

          <div className={style.card}>
            <div>
              <p className={style.text}>
                {lang === LANGUAGE_UA ? TERMS_AND_CONDITIONS_UA : TERMS_AND_CONDITIONS}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

TermsAndConditions.propTypes = {
  lang: PropTypes.string,
}

export default TermsAndConditions
