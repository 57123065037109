import React, { useState } from 'react'
import { Alert, Container, Stack } from 'react-bootstrap'
import { GoogleOAuthProvider } from '@react-oauth/google'

import GoogleAuthButton from '../components/GoogleAuthButton'
import Loader from '../components/Loader'

function GoogleAuthPage() {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  if (isLoading) {
    return <Loader />
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Container>
        <h1>Sign in with your Google Account</h1>
        <Stack className="col-md-5 mx-auto mt-5">
          <GoogleAuthButton setError={setError} setIsLoading={setIsLoading} />
          {error && (
            <Alert variant="danger">
              <b>Error</b>: {error}
            </Alert>
          )}
        </Stack>
      </Container>
    </GoogleOAuthProvider>
  )
}
export default GoogleAuthPage
