const Coffee = ({ customClassName = '' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M8 6.8265C7.45333 6.8265 7 6.37316 7 5.8265V3.49316C7 2.9465 7.45333 2.49316 8 2.49316C8.54667 2.49316 9 2.9465 9 3.49316V5.8265C9 6.3865 8.54667 6.8265 8 6.8265Z"
        fill="#4945FF"
      />
      <path
        d="M13.3334 6.8265C12.7867 6.8265 12.3334 6.37316 12.3334 5.8265V3.49316C12.3334 2.9465 12.7867 2.49316 13.3334 2.49316C13.88 2.49316 14.3334 2.9465 14.3334 3.49316V5.8265C14.3334 6.3865 13.88 6.8265 13.3334 6.8265Z"
        fill="#4945FF"
      />
      <path
        d="M18.6666 6.8265C18.12 6.8265 17.6666 6.37316 17.6666 5.8265V3.49316C17.6666 2.9465 18.12 2.49316 18.6666 2.49316C19.2133 2.49316 19.6666 2.9465 19.6666 3.49316V5.8265C19.6666 6.3865 19.2133 6.8265 18.6666 6.8265Z"
        fill="#4945FF"
      />
      <path
        d="M29.6667 17.6667C29.6667 14.1733 26.96 11.3467 23.5467 11.0533C22.56 9.44001 20.8 8.34668 18.7734 8.34668H8.94671C5.85337 8.34668 3.33337 10.8667 3.33337 13.96V14.6667H24.3867V13.96C24.3867 13.7067 24.3467 13.4533 24.3067 13.2133C26.24 13.7867 27.6667 15.5467 27.6667 17.6667C27.6667 19.76 26.28 21.5067 24.3867 22.0933V16H3.33337V23.72C3.33337 26.8133 5.85337 29.3333 8.94671 29.3333H18.7734C21.7067 29.3333 24.0934 27.0667 24.3334 24.1867C27.3734 23.5733 29.6667 20.88 29.6667 17.6667Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default Coffee
