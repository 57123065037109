import { useLocation } from 'react-router-dom'
import { FOOTER_SOCIAL, PUBLIC_ROUTES } from '../../../../utils/constants'
import styles from './index.module.css'

const Footer = () => {
  const location = useLocation()

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <div className={`w-100 d-flex align-items-center ${styles.footerContent}`}>
            <div className={styles.socials}>
              {FOOTER_SOCIAL.map(({ SocialIcon, link }) => (
                <a
                  key={link}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.socialItem}
                  href={link}
                >
                  <SocialIcon />
                </a>
              ))}
            </div>
            <p className={styles.footerCopyright}>Copyrighted © 2023 Pinta.AI</p>
            <a
              href={PUBLIC_ROUTES.TERMS_AND_CONDITIONS}
              className={`${styles.footerTermsConditions} ${
                location.pathname.startsWith('/terms-and-conditions') ? styles.disabled : ''
              }`}
            >
              {location.pathname.includes('/ua') ? 'Положення та умови' : 'Terms & Conditions'}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
