import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Button, Container, Form, Stack } from 'react-bootstrap'

import PasswordInput from '../components/PasswordInput'
import { checkRestorePassword, restorePassword } from '../services/userService'
import Loader from '../components/Loader'

function ChangePasswordPage() {
  const { userId, code } = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    checkRestorePassword(userId, code)
      .then(() => setIsLoading(false))
      .catch(() => navigate('/change-password-fail'))
  }, [])

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: '',
      passwordConfirmation: '',
    },
    mode: 'onChange',
  })

  const onSubmit = async (values) => {
    setIsLoading(true)
    await restorePassword(userId, code, {
      password: values.newPassword,
    })
      .then(() => navigate('/change-password-success'))
      .catch((error) => setError(error?.response?.data?.error?.message))
    setIsLoading(false)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Container>
      <Stack className="col-md-5 mx-auto mt-5">
        {error && (
          <Alert variant="danger">
            <b>Error</b>: {error}
          </Alert>
        )}
        <h1 className="mb-3">Change Password</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: {
                value: true,
                message: 'New password is required',
              },
              minLength: {
                value: 6,
                message: 'Too short password',
              },
              maxLength: {
                value: 50,
                message: 'Too long password',
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <PasswordInput
                placeholder="New password"
                onChange={(event) => onChange(event?.target?.value)}
                label={'New Password'}
                value={value}
                ref={ref}
                error={errors.newPassword}
              />
            )}
          />

          <Controller
            control={control}
            name="passwordConfirmation"
            rules={{
              required: {
                value: true,
                message: 'Password confirmation is required',
              },
              validate: (value) => {
                if (watch('newPassword') != value) {
                  return 'Your passwords do no match'
                }
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <PasswordInput
                placeholder="Password confirmation"
                onChange={(event) => onChange(event?.target?.value)}
                label={'Password Confirmation'}
                value={value}
                ref={ref}
                error={errors.passwordConfirmation}
              />
            )}
          />
          <Button type="submit" variant="outline-primary">
            Change
          </Button>
          <Form.Group className="error-message"> {error}</Form.Group>
        </Form>
      </Stack>
    </Container>
  )
}

export default ChangePasswordPage
