import { useCallback, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import Button from '../Button'
import ListMenu from '../ListMenu'
import MobileMenu from '../MobileMenu'
import ButtonHamburger from '../ButtonHamburger'
import { LANGUAGE_UA } from '../../../utils/constants'
import styles from './Header.module.css'

const Header = ({ lang }) => {
  const { hash } = useLocation()
  const [isActiveMenu, setIsActiveMenu] = useState(false)

  const toggleActivateMenu = useCallback(() => {
    setIsActiveMenu((oldState) => !oldState)
  }, [])

  return (
    <>
      <header className={styles.header}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <Image
              priority="true"
              width={114}
              height={46}
              alt="Logo"
              src="/images/positiveLogo.svg"
            />
            <ListMenu
              innerClassName={styles.desktopItems}
              lang={lang}
              onAfterClick={toggleActivateMenu}
            />
            <div className="d-flex align-items-center gap-3">
              {lang !== LANGUAGE_UA ? (
                <Button
                  isNegativeButton
                  customClassName={`getStartedButton ${hash === '#home' ? 'hidden' : ''}`}
                >
                  Get started
                </Button>
              ) : null}
              <ButtonHamburger isActive={isActiveMenu} onClickButton={toggleActivateMenu} />
            </div>
          </div>
        </div>
      </header>
      <MobileMenu lang={lang} onHideMenu={toggleActivateMenu} isOpened={isActiveMenu} />
    </>
  )
}

export default Header
