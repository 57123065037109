import React, { useRef } from 'react'
import Slider from 'react-slick'
import {
  HOME_GENERATION_EXAMPLES,
  HOME_GENERATION_EXAMPLES_UA,
  LANGUAGE_UA,
} from '../../../utils/constants'
import Button from '../Button'
import { ArrowLeft, ArrowRight } from '../SvgIcon'
import ExampleCard from './ExampleCard'
import './GenerationsExample.css'

function GenerationsExample({ lang }) {
  const sliderRef = useRef(null)

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <></>,
    nextArrow: <></>,
    ref: sliderRef,
    arrows: false,
    customPaging: () => <div className="examplesSliderPage" />,
    appendDots: (dots) => (
      <div>
        <div className="examplesSliderActionsBlock">
          <Button
            customClassName="examplesSliderButton"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <ArrowLeft customClassName="examplesSliderArrow" />
            <div className="examplesSliderArrowText">{lang === LANGUAGE_UA ? 'Назад' : 'Prev'}</div>
          </Button>
          <ul className="examplesSliderDots">{dots}</ul>
          <Button
            customClassName="examplesSliderButton"
            onClick={() => sliderRef.current.slickNext()}
          >
            <div className="examplesSliderArrowText">
              {lang === LANGUAGE_UA ? 'Вперед' : 'Next'}
            </div>
            <ArrowRight customClassName="examplesSliderArrow" />
          </Button>
        </div>
      </div>
    ),
  }
  return (
    <div className="examplesBlock">
      <Slider {...sliderSettings}>
        {lang === LANGUAGE_UA
          ? HOME_GENERATION_EXAMPLES_UA.map(({ offer, proposal }) => (
              <div key={proposal.title}>
                <div className="examplesSliderItem">
                  <ExampleCard
                    headerImageSrc="/images/upworkLogo.svg"
                    headerImageAlt="Upwork Logo"
                    headerText="Інформація про профіль"
                    title={offer.title}
                    text={offer.text}
                  />
                  <ExampleCard
                    headerImageSrc="/images/logoIcon.svg"
                    headerImageAlt="Pinta Logo"
                    headerText="Згенерований результат"
                    title={proposal.title}
                    description={proposal.description}
                    text={proposal.text}
                  />
                </div>
              </div>
            ))
          : HOME_GENERATION_EXAMPLES.map(({ offer, proposal }) => (
              <div key={proposal.title}>
                <div className="examplesSliderItem">
                  <ExampleCard
                    headerImageSrc="/images/upworkLogo.svg"
                    headerImageAlt="Upwork Logo"
                    headerText="Offer example"
                    title={offer.title}
                    text={offer.text}
                  />
                  <ExampleCard
                    headerImageSrc="/images/logoIcon.svg"
                    headerImageAlt="Pinta Logo"
                    headerText="Example of generated text"
                    title={proposal.title}
                    description={proposal.description}
                    text={proposal.text}
                  />
                </div>
              </div>
            ))}
      </Slider>
    </div>
  )
}

export default GenerationsExample
