import React, { useState } from 'react'
import Slider from 'react-slick'
import { Image } from 'react-bootstrap'

import {
  HOME_HOW_IT_WORKS_IMAGES,
  HOME_HOW_IT_WORKS_TITLES,
  HOME_HOW_IT_WORKS_TITLES_UA,
  LANGUAGE_UA,
} from '../../../utils/constants'
import './HowItWorks.css'
import { ArrowLeft, ArrowRight } from '../SvgIcon'
import Button from '../Button'

function HowItWorks({ lang }) {
  const [activeTitleSlide, setActiveTitleSlide] = useState(0)
  const [titlesSlider, setTitlesSlider] = useState()
  const [imagesSlider, setImagesSlider] = useState()
  const [textSlider, setTextSlider] = useState()

  const imagesSliderSettings = {
    arrows: false,
    infinite: false,
    fade: true,
    asNavFor: textSlider,
    ref: (e) => setImagesSlider(e),
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    beforeChange: (currentSlide, nextSlide) => {
      setActiveTitleSlide(nextSlide)
    },
    responsive: [
      {
        breakpoint: 1121,
        settings: {
          fade: false,
        },
      },
    ],
  }

  const textSliderSettings = {
    arrows: false,
    infinite: false,
    asNavFor: titlesSlider,
    ref: (e) => setTextSlider(e),
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
  }

  const titlesSliderSettings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    verticalSwiping: true,
    initialSlide: 1,
    centerPadding: '35px',
    asNavFor: imagesSlider,
    vertical: true,
    ref: (e) => setTitlesSlider(e),
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1121,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
          centerPadding: 0,
          variableWidth: true,
        },
      },
    ],
  }

  const sliderDots = []

  for (let i = 0; i < HOME_HOW_IT_WORKS_TITLES.length; i++) {
    sliderDots.push(
      <li
        key={`titlesDots + ${i}`}
        onClick={() => titlesSlider.slickGoTo(i)}
        className={activeTitleSlide === i ? 'slick-active' : ''}
      >
        <div className="titlesSliderPage" />
      </li>
    )
  }

  return (
    <div className="container position-relative">
      <div className="howItWorksContainer">
        {lang === LANGUAGE_UA ? (
          <>
            <h2 className={`blockTitle howItWorksTitle`}>
              Як Це <span>Працює</span>
            </h2>
            <p className="howItWorksDescription">
              Pinta.AI створює індивідуальні супроводжувальні листи для UpWork, аналізуючи описи
              проектів та інформацію про профіль.
            </p>
          </>
        ) : (
          <>
            <h2 className={`blockTitle howItWorksTitle`}>
              How It <span>Works</span>
            </h2>
            <p className="howItWorksDescription">
              Pinta.AI generates tailored UpWork proposals by analyzing project descriptions and
              profile information to select the most suitable expertise for the job.
            </p>
          </>
        )}
        <div className="imagesSlider">
          <Slider {...imagesSliderSettings}>
            {HOME_HOW_IT_WORKS_IMAGES.map(({ src, alt }) => (
              <div key={alt}>
                <Image src={src} alt={alt} className="imagesSliderItem" />
              </div>
            ))}
          </Slider>
        </div>
        <div className="position-relative titlesSlider">
          <Slider {...titlesSliderSettings}>
            {lang === LANGUAGE_UA
              ? HOME_HOW_IT_WORKS_TITLES_UA.map((title, key) => (
                  <div key={title} className="innerSliderItem">
                    <div className="titlesItemLine before" />

                    <div className="titlesSliderItem">
                      <div className="titlesSliderDot">
                        <div className="titlesSliderDotNumber">{key + 1}</div>
                      </div>
                      <p className="titlesSliderItemText">{title}</p>
                    </div>

                    <div className="titlesItemLine after" />
                  </div>
                ))
              : HOME_HOW_IT_WORKS_TITLES.map((title, key) => (
                  <div key={title} className="innerSliderItem">
                    <div className="titlesItemLine before" />

                    <div className="titlesSliderItem">
                      <div className="titlesSliderDot">
                        <div className="titlesSliderDotNumber">{key + 1}</div>
                      </div>
                      <p className="titlesSliderItemText">{title}</p>
                    </div>

                    <div className="titlesItemLine after" />
                  </div>
                ))}
          </Slider>
          <div className="slick-dots titlesSliderPageDots">{sliderDots}</div>
        </div>
        <div className="titlesTextSlider">
          <Slider {...textSliderSettings}>
            {lang === LANGUAGE_UA
              ? HOME_HOW_IT_WORKS_TITLES_UA.map((title, key) => (
                  <div key={title + key}>{title}</div>
                ))
              : HOME_HOW_IT_WORKS_TITLES.map((title, key) => <div key={title + key}>{title}</div>)}
          </Slider>
        </div>
        <div className="titlesSliderButtons">
          <Button customClassName="titlesSliderButton" onClick={() => titlesSlider.slickPrev()}>
            <ArrowLeft customClassName="titlesSliderArrow" />
            {lang === LANGUAGE_UA ? 'Назад' : 'Prev'}
          </Button>
          <Button customClassName="titlesSliderButton" onClick={() => titlesSlider.slickNext()}>
            {lang === LANGUAGE_UA ? 'Вперед' : 'Next'}
            <ArrowRight customClassName="titlesSliderArrow" />
          </Button>
        </div>
      </div>
      {lang !== LANGUAGE_UA ? (
        <Button isNegativeButton customClassName="howItWorksGetStarted">
          Get started
        </Button>
      ) : null}

      {lang !== LANGUAGE_UA && (
        <div className="howItWorksPaymentsBlock">
          <Image
            src={'/images/visaMastercardLogo.png'}
            alt="Visa/MasterCard"
            className="howItWorksVisaMastercardLogo"
          />
          <Image src={'/images/liqpayLogo.svg'} alt="LiqPay" className="howItWorksLiqpayLogo" />
        </div>
      )}
    </div>
  )
}

export default HowItWorks
