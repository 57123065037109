import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, CloseButton, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { CheckCircle, XCircle } from 'react-bootstrap-icons'
import { STATIC_FIELDS } from '../../../../utils/constants'

function CreateFieldModal({ setModal, setFields, fields, field = null, setScriptValue, textArea }) {
  const [error, setError] = useState('')

  const closeModal = () => setModal(null)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    setValue('name', field?.name)
    setValue('description', field?.description)
  }, [])

  const checkIsFieldAlreadyExists = (values) => {
    const filteredFields = fields.filter(({ name }) => field?.name !== name)

    const fieldFound =
      filteredFields.find(({ name }) => name === values.name) ||
      STATIC_FIELDS.find((staticField) => staticField === values.name)

    return fieldFound
  }

  const onCreateSubmit = (values) => {
    if (checkIsFieldAlreadyExists(values)) return setError('Field with this name already exists')

    setFields([...fields, values])
    closeModal()
  }

  const onEditSubmit = (values) => {
    if (checkIsFieldAlreadyExists(values)) return setError('Field with this name already exists')

    setFields([
      ...fields.map((scriptField) =>
        scriptField.name === field.name
          ? {
              _id: field?._id,
              name: values.name,
              description: values.description,
            }
          : scriptField
      ),
    ])

    const value = textArea.current.value
    setScriptValue('value', value.replaceAll(`{${field.name}}`, `{${values.name}}`))

    closeModal()
  }

  const onSubmit = field ? onEditSubmit : onCreateSubmit

  return (
    <Modal show onHide={closeModal} size="xs">
      <Modal.Header>
        {field ? `Edit ${field?.name}` : 'Create'} Field <CloseButton onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Controller
              control={control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: 'Name is required',
                },
              }}
              render={({ field: { onChange, value, ref } }) => (
                <>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </>
              )}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Description</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder="Enter description"
                  onChange={onChange}
                  value={value}
                  ref={ref}
                />
              )}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Body>
        {error && (
          <Alert variant="danger">
            <b>Error</b>: {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ position: 'absolute', left: '13px' }}
          variant="success"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          <CheckCircle className="button-icon" /> {field ? 'Update' : 'Create'}
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          <XCircle className="button-icon" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

CreateFieldModal.propTypes = {
  setModal: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  textArea: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  setScriptValue: PropTypes.func,
}

export default CreateFieldModal
