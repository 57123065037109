import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

import Header from './Header'

function AdminLayout({ isLoggedIn, setIsLoggedIn }) {
  return (
    <>
      {isLoggedIn ? <Header setIsLoggedIn={setIsLoggedIn} /> : null}
      <Outlet />
    </>
  )
}

AdminLayout.propTypes = {
  isLoggedIn: PropTypes.string,
  setIsLoggedIn: PropTypes.func.isRequired,
}

export default AdminLayout
