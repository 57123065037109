import { useEffect, useState } from 'react'
import { Alert, Button, Container, Form, Stack } from 'react-bootstrap'
import { Envelope, Send } from 'react-bootstrap-icons'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import 'react-phone-number-input/style.css'

import { sendPhoneVerificationCode, verifyPhone } from '../services/authService'
import { LOCAL_STORAGE } from '../utils/constants'

function PhoneVerifyForm({ userId }) {
  const [error, setError] = useState('')
  const [isDisabledResent, setIsDisabledResent] = useState(true)
  const [confirmLabel, setConfirmLabel] = useState(
    <>
      <Send className="button-icon" /> Send Code
    </>
  )
  const [seconds, setSeconds] = useState(0)
  const [isConfirm, setIsConfirm] = useState(false)
  const navigate = useNavigate()

  const timer = seconds === 0 ? null : <>0:{seconds < 10 ? `0${seconds}` : seconds}</>

  const { control, handleSubmit, formState, setValue, getFieldState, watch } = useForm({
    defaultValues: {
      phoneNumber: '',
      code: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 1) {
        return setSeconds(seconds - 1)
      }
      clearInterval(myInterval)
      localStorage.removeItem(LOCAL_STORAGE.PHONE_VERIFICATION_DATA)
      setSeconds(0)
      setIsDisabledResent(false)
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [seconds])

  useEffect(() => {
    const verificationData = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PHONE_VERIFICATION_DATA))
    if (verificationData) {
      const endDate = moment(verificationData.endDate)
      const now = moment()
      if (now.isAfter(endDate) || verificationData.userId !== userId) {
        localStorage.removeItem(LOCAL_STORAGE.PHONE_VERIFICATION_DATA)
      } else {
        setSeconds(endDate.diff(now, 'seconds'))
        setIsConfirm(true)
        setValue('phoneNumber', verificationData.phoneNumber)
      }
    }
  }, [])

  const resendCode = () => {
    if (!getFieldState('phoneNumber').invalid) {
      sendPhoneVerificationCode(userId, { phoneNumber: watch('phoneNumber') })
        .then(() => {
          localStorage.setItem(
            LOCAL_STORAGE.PHONE_VERIFICATION_DATA,
            JSON.stringify({
              userId,
              phoneNumber: watch('phoneNumber'),
              endDate: moment().add(1, 'm'),
            })
          )
          setSeconds(59)
          setIsDisabledResent(true)
        })
        .catch((error) => setError(error?.response?.data?.error?.message))

      setTimeout(() => {
        setError('')
      }, 5000)
    }
  }

  const onSubmit = async (values) => {
    if (!isConfirm) {
      await sendPhoneVerificationCode(userId, { phoneNumber: values.phoneNumber })
        .then(() => {
          setIsConfirm(true)
          setConfirmLabel(
            <>
              <Envelope className="button-icon" /> Confirm
            </>
          )
          localStorage.setItem(
            LOCAL_STORAGE.PHONE_VERIFICATION_DATA,
            JSON.stringify({
              userId,
              phoneNumber: values.phoneNumber,
              endDate: moment().add(1, 'm'),
            })
          )
          setSeconds(59)
          setIsDisabledResent(true)
        })
        .catch((error) => setError(error?.response?.data?.error?.message))
    } else {
      await verifyPhone(userId, values.code)
        .then(({ data }) => {
          navigate(`/verify-success/${data.token}`)
          navigate(0)
        })
        .catch((error) => setError(error?.response?.data?.error?.message))

      localStorage.removeItem(LOCAL_STORAGE.PHONE_VERIFICATION_DATA)
    }

    await setTimeout(() => {
      setError('')
    }, 5000)
  }
  return (
    <Container fluid="lg">
      <Stack className="col-md-5 mx-auto">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Phone</Form.Label>
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: {
                  value: true,
                  message: 'Phone is required',
                },
                validate: (value) => {
                  if (!isValidPhoneNumber(value)) return 'Phone number is not valid'
                },
              }}
              render={({ field }) => (
                <div className={`form-control ${formState.errors.phoneNumber ? 'is-invalid' : ''}`}>
                  <PhoneInput
                    {...field}
                    limitMaxLength={true}
                    international={true}
                    defaultCountry="US"
                  />
                </div>
              )}
            />
          </Form.Group>
          {isConfirm ? (
            <>
              <Form.Group className="mt-3">
                <Form.Label>Verification code</Form.Label>
                <Controller
                  control={control}
                  name="code"
                  rules={{
                    required: {
                      value: isConfirm,
                      message: 'Verification code is required',
                    },
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <>
                      <Form.Control
                        type="text"
                        placeholder="Enter verification code"
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        isInvalid={formState.errors.code}
                      />
                    </>
                  )}
                />
              </Form.Group>
            </>
          ) : null}

          <Form.Group className="mt-3">
            <Button variant="success" type="submit" onClick={handleSubmit(onSubmit)}>
              {confirmLabel}
            </Button>
          </Form.Group>
          {isConfirm ? (
            <>
              <Form.Group className="mt-3">
                <Form.Label>If you haven't received the code, click the button {timer}</Form.Label>
              </Form.Group>
              <Button
                variant="link"
                disabled={isDisabledResent}
                type="button"
                onClick={resendCode}
                className="resendCode"
              >
                Resend code
              </Button>
            </>
          ) : null}

          {(error || formState.errors.phoneNumber) && (
            <Alert variant="danger" className="mt-3">
              <b>Error</b>: {error || formState.errors.phoneNumber?.message}
            </Alert>
          )}
        </Form>
      </Stack>
    </Container>
  )
}

PhoneVerifyForm.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default PhoneVerifyForm
