const MessageQuestion = ({ customClassName }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M17 2.43018H7C4 2.43018 2 4.43018 2 7.43018V13.4302C2 16.4302 4 18.4302 7 18.4302V20.5602C7 21.3602 7.89 21.8402 8.55 21.3902L13 18.4302H17C20 18.4302 22 16.4302 22 13.4302V7.43018C22 4.43018 20 2.43018 17 2.43018ZM12 14.6002C11.58 14.6002 11.25 14.2602 11.25 13.8502C11.25 13.4402 11.58 13.1002 12 13.1002C12.42 13.1002 12.75 13.4402 12.75 13.8502C12.75 14.2602 12.42 14.6002 12 14.6002ZM13.26 10.4502C12.87 10.7102 12.75 10.8802 12.75 11.1602V11.3702C12.75 11.7802 12.41 12.1202 12 12.1202C11.59 12.1202 11.25 11.7802 11.25 11.3702V11.1602C11.25 10.0002 12.1 9.43018 12.42 9.21018C12.79 8.96018 12.91 8.79018 12.91 8.53018C12.91 8.03018 12.5 7.62018 12 7.62018C11.5 7.62018 11.09 8.03018 11.09 8.53018C11.09 8.94018 10.75 9.28018 10.34 9.28018C9.93 9.28018 9.59 8.94018 9.59 8.53018C9.59 7.20018 10.67 6.12018 12 6.12018C13.33 6.12018 14.41 7.20018 14.41 8.53018C14.41 9.67018 13.57 10.2402 13.26 10.4502Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default MessageQuestion
