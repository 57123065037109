import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, CloseButton, Form, Modal, Spinner } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { CheckCircle, XCircle } from 'react-bootstrap-icons'
import { createScript, getScriptById, updateScript } from '../../../services/admin/scriptService'
import FieldsForm from './Fields'

function UpsertScriptModal({ setModal, setScriptUpdated, scriptId = null }) {
  const [error, setError] = useState('')
  const [fields, setFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const textArea = useRef(null)

  useEffect(() => {
    if (scriptId) {
      setIsLoading(true)
      getScriptById(scriptId)
        .then(({ data }) => {
          reset({
            title: data.title,
            value: data.value,
            description: data.description,
          })

          setIsLoading(false)
          setFields(data.fields)
        })
        .catch((error) => setError(error?.response?.data?.error?.message))
      setIsLoading(false)
    }
  }, [])

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      value: '',
      description: '',
    },
    mode: 'onChange',
  })

  const onSubmit = async (values) => {
    const notAddedFields = fields.filter(({ name }) => values.value.search(`{${name}}`) === -1)

    let fieldNames = []
    notAddedFields.forEach((field) => {
      fieldNames.push(field.name)
    })

    if (notAddedFields.length > 0) {
      setError(`Some fields are not added to text: ${fieldNames.join(', ')}`)
      return
    }

    setIsLoading(true)
    if (scriptId) {
      await updateScript(scriptId, { ...values, fields })
        .then(() => {
          closeModal()
          setScriptUpdated(true)
          reset()
          setError()
        })
        .catch((error) => setError(error?.response?.data?.error?.message))
    } else {
      await createScript({ ...values, fields })
        .then(() => {
          closeModal()
          setScriptUpdated(true)
          reset()
          setError()
        })
        .catch((error) => setError(error?.response?.data?.error?.message))
    }

    setIsLoading(false)
  }

  const closeModal = () => setModal(null)

  return (
    <Modal show onHide={closeModal} size="lg">
      <Modal.Header>
        {scriptId ? 'Edit' : 'Create'} Script{' '}
        {isLoading ? <Spinner animation={'border'} /> : <CloseButton onClick={closeModal} />}
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Controller
              control={control}
              name="title"
              rules={{
                required: {
                  value: true,
                  message: 'Title is required',
                },
              }}
              render={({ field: { onChange, value, ref } }) => (
                <>
                  <Form.Control
                    disabled={isLoading}
                    type="text"
                    placeholder="Enter title"
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    isInvalid={errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title?.message}
                  </Form.Control.Feedback>
                </>
              )}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Description</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value, ref } }) => (
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter description"
                  onChange={onChange}
                  value={value}
                  ref={ref}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Text</Form.Label>
            <Controller
              control={control}
              name="value"
              rules={{
                required: {
                  value: true,
                  message: 'Text is required',
                },
                validate: (value) => {
                  if (!value.match(/^(?:[^{}]*{[^{}]*})*[^{}]*$/)) {
                    return 'All curly braces must be closed'
                  }

                  if (!value.match(/[^{}]*(?=\})/g)) return 'Text must have curly braces'
                },
              }}
              render={({ field: { onChange, value, ref } }) => (
                <>
                  <Form.Control
                    disabled={isLoading}
                    as="textarea"
                    rows={16}
                    placeholder="Enter text"
                    onChange={onChange}
                    value={value}
                    ref={(e) => {
                      ref(e)
                      textArea.current = e
                    }}
                    isInvalid={errors.value}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.value?.message}
                  </Form.Control.Feedback>
                </>
              )}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Body>
        <FieldsForm
          setError={setError}
          textArea={textArea}
          setValue={setValue}
          fields={fields}
          setFields={setFields}
          isLoading={isLoading}
          clearErrors={clearErrors}
        />
        {error && (
          <Alert variant="danger">
            <b>Error</b>: {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ position: 'absolute', left: '13px' }}
          variant="success"
          disabled={isLoading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          <CheckCircle className="button-icon" /> Update
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          <XCircle className="button-icon" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

UpsertScriptModal.propTypes = {
  setModal: PropTypes.func.isRequired,
  setScriptUpdated: PropTypes.func.isRequired,
  scriptId: PropTypes.string,
}

export default UpsertScriptModal
