import React from 'react'
import style from './YouWillItem.module.css'

function YouWillItem({ Icon, title, text }) {
  return (
    <div className={style.block}>
      <div className={style.iconBlock}>
        <Icon customClassName={style.icon} />
      </div>
      <div className={style.content}>
        <h3 className={style.title}>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  )
}

YouWillItem.propTypes = {}

export default YouWillItem
