import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { Trash, XCircle } from 'react-bootstrap-icons'
import { deleteScriptById } from '../../../services/admin/scriptService'

const DeleteConfirmModal = ({ setModal, setScriptUpdated, scriptId, setIsLoading }) => {
  const closeUpdateModal = () => setModal(null)

  return (
    <Modal show onHide={closeUpdateModal}>
      <Modal.Header>Are you sure to delete this script?</Modal.Header>
      <Modal.Footer>
        <Button
          variant="danger"
          style={{ position: 'absolute', left: '13px' }}
          onClick={async () => {
            setIsLoading(true)
            await deleteScriptById(scriptId)
            setScriptUpdated(true)
            closeUpdateModal()
          }}
        >
          <Trash className="button-icon" /> Delete
        </Button>
        <Button className="btn-block ml-1" variant="secondary" onClick={closeUpdateModal}>
          <XCircle className="button-icon" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

DeleteConfirmModal.propTypes = {
  setModal: PropTypes.func.isRequired,
  setScriptUpdated: PropTypes.func.isRequired,
  scriptId: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

export default DeleteConfirmModal
