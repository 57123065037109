import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Loader from '../components/Loader'
import PhoneVerifyForm from '../components/PhoneVerifyForm'
import { getStatus } from '../services/userService'
import { AUTH_TYPE, USER_STATUS } from '../utils/constants'

function PhoneVerifyPage() {
  const { authType, userId } = useParams()
  const navigate = useNavigate()
  const [header, setHeader] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = async () => {
    await getStatus(userId)
      .then(({ data }) => {
        switch (data.status) {
          case USER_STATUS.EMAIL_VERIFIED: {
            if (authType === AUTH_TYPE.EMAIL) setHeader('Verification Success')
            else if (authType === AUTH_TYPE.GOOGLE) setHeader('Google Authorization Success')
            else navigate('/not-found')
            break
          }
          case USER_STATUS.BLOCKED: {
            navigate('/user-blocked')
            break
          }
          case USER_STATUS.ACTIVE: {
            navigate('/user-active')
            break
          }
          case USER_STATUS.NON_VERIFIED: {
            navigate('/user-not-verified')
            break
          }
          default:
            navigate('/not-found')
        }
      })
      .catch(() => navigate('/not-found'))

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          <h1>{header}</h1>
          <p>Please, verify phone number to use all features.</p>
          <PhoneVerifyForm userId={userId} />
        </section>
      )}
    </>
  )
}

export default PhoneVerifyPage
