const CloudFourth = ({ customClassName = '', width = '136', height = '51' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 136 51"
      className={customClassName}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.91 20.7995C118.923 20.7995 117.037 21.1866 115.314 21.8732C115.477 21.3489 115.591 20.7995 115.591 20.2252C115.591 17.1415 113.076 14.6446 109.969 14.6446C109.391 14.6446 108.85 14.7569 108.322 14.9192C108.322 14.8318 108.335 14.7444 108.335 14.6446C108.335 9.61323 104.223 5.54321 99.1674 5.54321C95.9733 5.54321 93.1565 7.16622 91.5092 9.63819C90.8553 7.27858 88.705 5.54321 86.1145 5.54321C84.2031 5.54321 82.5306 6.49206 81.512 7.94029C76.8466 3.05877 70.2572 0 62.9511 0C49.5083 0 38.505 10.2874 37.4613 23.3714C35.0469 21.7483 32.142 20.7995 29.0108 20.7995C21.2142 20.7995 14.8135 26.6548 13.9961 34.1706C12.5877 33.3092 10.9404 32.7973 9.16728 32.7973C4.0995 32.7973 0 36.8798 0 41.8986C0 46.93 4.11208 51 9.16728 51H120.533V50.7503C120.658 50.7503 120.772 50.7628 120.897 50.7628C129.235 50.7628 136 44.046 136 35.7687C136.013 27.5163 129.26 20.7995 120.91 20.7995Z"
        fill="#EBF0F8"
      />
    </svg>
  )
}

export default CloudFourth
