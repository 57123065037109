import styles from './ButtonHamburger.module.css'

const ButtonHamburger = ({ isActive, onClickButton }) => {
  const activeClass = isActive ? styles.hamburgerActive : ''

  return (
    <div onClick={onClickButton} className={`${styles.hamburger} ${activeClass}`}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </div>
  )
}

export default ButtonHamburger
