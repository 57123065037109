import React from 'react'
import PropTypes from 'prop-types'

import style from './Item.module.css'

function Item({ header, price, items = [] }) {
  return (
    <div className={style.pricingColumns}>
      <ul className={style.pricingItem}>
        <li className={style.pricingHeader} style={{ backgroundColor: header.color }}>
          {header.text}
        </li>
        <li className={style.pricingGray}>{price}</li>
        {items.map((item) => (
          <li key={header + item}>{item}</li>
        ))}
      </ul>
    </div>
  )
}

Item.propTypes = {
  header: PropTypes.shape({
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  price: PropTypes.string.isRequired,
  items: PropTypes.array,
}

export default Item
