import React from 'react'
import PropTypes from 'prop-types'
import { useGoogleLogin } from '@react-oauth/google'
import { Button, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { googleAuth } from '../services/authService'
import googleLogo from '../googleLogo.svg'
import { AUTH_TYPE } from '../utils/constants'

function GoogleAuthButton({ setError, setIsLoading }) {
  const navigate = useNavigate()

  const auth = useGoogleLogin({
    onSuccess: async (token) => {
      setIsLoading(true)
      await googleAuth(token.access_token)
        .then(({ data }) => {
          if (data.isNew) {
            navigate(`/phone-verify/${AUTH_TYPE.GOOGLE}/${data.userId}`)
          } else {
            navigate(`/google-auth-success/${data.token}`)
            navigate(0)
          }
        })
        .catch((error) => {
          if (error?.response?.data?.error?.message) {
            setError(error.response.data.error.message)
          } else if (error?.response?.data?.message) {
            setError(error.response.data.message)
          } else {
            setError('No Server Response')
          }
        })
      setIsLoading(false)
    },
    onError: (err) => {
      setError(err)
    },
  })
  return (
    <Button
      id="google-button"
      variant="light"
      style={{ width: '100%', borderRadius: '80px' }}
      onClick={() => auth()}
    >
      <Image src={googleLogo} alt="Google Logo" />
      {' Sign in with Google'}
    </Button>
  )
}

GoogleAuthButton.propTypes = {
  setError: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

export default GoogleAuthButton
