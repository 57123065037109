import React from 'react'
import style from './index.module.css'

function Loader() {
  return (
    <div className={style.loaderContainer}>
      <div className={style.loaderItem}>
        <div className={style.loaderSpinner} />
      </div>
    </div>
  )
}

export default Loader
