const DoYouCloud = ({ customClassName = '' }) => {
  return (
    <svg
      width="162"
      height="52"
      viewBox="0 0 162 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M132.68 37.3749C131.117 37.3749 129.675 37.8611 128.487 38.6916C128.528 38.2561 128.558 37.8206 128.558 37.3749C128.558 29.4243 122.091 22.9727 114.122 22.9727C110.599 22.9727 107.371 24.2387 104.863 26.3353C104.213 24.1071 102.152 22.4764 99.7056 22.4764C98.7919 22.4764 97.9289 22.7094 97.1777 23.1044C97.2792 22.5879 97.3299 22.0511 97.3299 21.5042C97.3299 16.673 93.401 12.7636 88.5685 12.7636C85.2893 12.7636 82.4467 14.5563 80.9442 17.2098C77.0761 8.82372 68.5888 3 58.731 3C45.6751 3 35.0152 13.2092 34.3249 26.0618C31.868 24.1375 28.7817 22.9727 25.4112 22.9727C17.4416 22.9727 10.9746 29.4243 10.9746 37.3749C10.9746 37.7193 11.0051 38.0434 11.0254 38.3777C9.93909 37.7396 8.6802 37.3749 7.32995 37.3749C3.27919 37.3749 0 40.6463 0 44.6875C0 48.7286 3.27919 52 7.32995 52H132.67C136.721 52 140 48.7286 140 44.6875C140.01 40.6463 136.731 37.3749 132.68 37.3749Z"
        fill="#FAFBFC"
      />
      <path
        d="M157.04 7.88542C155.69 7.88542 154.473 8.43751 153.573 9.32293C153.573 9.20835 153.594 9.10417 153.594 8.98959C153.594 4.02084 149.636 0 144.768 0C141.035 0 137.855 2.36458 136.556 5.69792C136.096 5.34375 135.533 5.12501 134.92 5.12501C133.417 5.12501 132.2 6.3646 132.2 7.89585C132.2 8.09377 132.22 8.29168 132.261 8.47918C131.576 8.1146 130.788 7.89585 129.96 7.89585C127.219 7.89585 125 10.1562 125 12.9479C125 15.7396 127.219 18 129.96 18H157.04C159.781 18 162 15.7396 162 12.9479C162 10.1458 159.781 7.88542 157.04 7.88542Z"
        fill="#F8FAFD"
      />
    </svg>
  )
}

export default DoYouCloud
