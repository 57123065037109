import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Loader from '../components/Loader'
import { checkoutPayment } from '../services/paymentService'

const PaymentPage = () => {
  const [data, setData] = useState()
  const [signature, setSignature] = useState()
  const { userId } = useParams()
  const submitButton = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    checkoutPayment(userId)
      .then(({ data }) => {
        setData(data.data)
        setSignature(data.signature)
        setTimeout(() => {
          submitButton.current.click()
        }, 1000)
      })
      .catch(() => navigate('/not-found'))
  }, [])

  return (
    <>
      <Loader />
      <form
        method="POST"
        action="https://www.liqpay.ua/api/3/checkout"
        acceptCharset="utf-8"
        hidden
      >
        <input type="hidden" name="data" value={data} />
        <input type="hidden" name="signature" value={signature} />
        <input ref={submitButton} type="image" name="btn_text" />
      </form>
    </>
  )
}

export default PaymentPage
