import styles from './Button.module.css'

const Button = ({
  children,
  onClick = () => {},
  customClassName = '',
  isNegativeButton = false,
}) => {
  const className = isNegativeButton ? styles.positiveButton : styles.negativeButton

  return (
    <button onClick={onClick} className={`${styles.button} ${className} ${customClassName}`}>
      {children}
    </button>
  )
}

export default Button
