import Header from './Header'
import Footer from './Footer'

const Layout = ({ lang, children }) => {
  return (
    <main className="landing">
      <Header lang={lang} />
      <div className="layoutWrap">{children}</div>
      <Footer lang={lang} />
    </main>
  )
}

export default Layout
