import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { ArrowLeftCircle, PersonFillX } from 'react-bootstrap-icons'
import moment from 'moment'

import { getAllUserPayments, getUserById } from '../../../services/admin/adminService'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../utils/constants'
import Pagination from '../../../components/Pagination'
import BlockConfirmModal from '../../../components/admin/User/BlockConfirm'

const UserDetails = () => {
  const [modal, setModal] = useState(null)
  const [user, setUser] = useState([])
  const [payments, setPayments] = useState([])
  const [paymentsAmount, setPaymentsAmount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [userUpdated, setUserUpdated] = useState(false)

  const { userId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)

    getAllUserPayments(userId, currentPage, DEFAULT_ITEMS_PER_PAGE)
      .then(({ data }) => {
        setPayments(data.payments)
        setPaymentsAmount(data.totalAmount)
        setIsLoading(false)
      })
      .catch((error) => console.log(error))
  }, [currentPage])

  useEffect(() => {
    getUserById(userId)
      .then(({ data }) => {
        setUser(data)
      })
      .catch((error) => console.log(error))
  }, [userUpdated])

  const showBlockModal = (userId) => {
    setModal(
      <BlockConfirmModal
        setModal={setModal}
        setUserUpdated={setUserUpdated}
        userId={userId}
        setIsLoading={setIsLoading}
      />
    )
  }

  const handlePageChange = (page) => setCurrentPage(page)
  return (
    <>
      {isLoading ? <Spinner animation={'border'} className="form-spinner" /> : null}
      {modal}
      <Container
        fluid="xxl"
        className="mt-3"
        style={isLoading ? { filter: `blur(3px)`, pointerEvents: 'none' } : {}}
      >
        <Row>
          <Col>
            <ArrowLeftCircle
              className="float-start clickable-item back-button"
              size={30}
              onClick={() => navigate(-1)}
            />
          </Col>
        </Row>
        <Row>
          <h1>User details</h1>
        </Row>
        <Table bordered style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
          <td></td>
          {user.userStatus !== 'Blocked' ? (
            <Button
              className="float-end mb-2"
              variant="outline-danger"
              onClick={() => showBlockModal(user._id)}
            >
              <PersonFillX className="button-icon" /> Block
            </Button>
          ) : (
            <Button className="float-end" variant="secondary" disabled>
              Blocked
            </Button>
          )}
          <tbody>
            <tr>
              <td>Id</td>
              <td>{user._id}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{user.userStatus}</td>
            </tr>
            <tr>
              <td>Billing Status</td>
              <td>{user.billingStatus}</td>
            </tr>
            <tr>
              <td>Is Authorized By Google</td>
              <td>{user.isAuthorizedByGoogle ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Remaining Generations</td>
              <td>{user.remainingGenerations}</td>
            </tr>
            <tr>
              <td>Amount GPT Requests</td>
              <td>{user.amountGptRequests}</td>
            </tr>
            <tr>
              <td>Used GPT Tokens</td>
              <td>{user.usedGptTokens}</td>
            </tr>
          </tbody>
        </Table>
        <Row className="mt-5">
          <h1>Payments</h1>
        </Row>
        <Table bordered responsive="sm" className="mt-5">
          <thead scope="row">
            <tr>
              <th>Amount</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Paid At</th>
              <th>Id</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={index}>
                <td>
                  {payment.amount} {payment.currency}
                </td>
                <td>{payment.status}</td>
                <td>
                  {payment.createdAt ? moment(payment.createdAt).format('YYYY-MM-DD HH:mm') : null}
                </td>
                <td>
                  {payment.paidAt
                    ? moment(Date.parse(payment.paidAt)).format('YYYY-MM-DD HH:mm')
                    : null}
                </td>
                <td>{payment._id}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
          totalItems={paymentsAmount}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  )
}

export default UserDetails
