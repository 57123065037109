import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Button, Container, Form, Stack } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { BoxArrowInRight } from 'react-bootstrap-icons'

import { login } from '../../services/admin/adminService'
import PasswordInput from '../../components/PasswordInput'

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

function LoginPage({ setIsLoggedIn }) {
  const [error, setError] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  const onSubmit = async (values) => {
    try {
      const { data } = await login(values)
      if ('token' in data) {
        localStorage.setItem('jwtToken', data.token)
        setIsLoggedIn(data.token)
      }
    } catch (error) {
      setError(error?.response?.data?.error?.message)
    }
  }

  const emailInput = ({ field: { onChange, value, ref } }) => (
    <>
      <Form.Control
        type="text"
        placeholder="Enter email address here"
        onChange={onChange}
        value={value}
        ref={ref}
        isInvalid={errors.email}
      />
      <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
    </>
  )

  return (
    <Container fluid="lg">
      <Stack className="col-md-5 mx-auto mt-5">
        {error && (
          <Alert variant="danger">
            <b>Error</b>: {error}
          </Alert>
        )}
        <h1 className="mb-3">Sign In</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="m-3">
            <Form.Label as="h4">E-Mail</Form.Label>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: emailPattern,
                  message: 'Invalid email address',
                },
              }}
              render={emailInput}
            />
          </Form.Group>

          <Controller
            control={control}
            name="password"
            rules={{
              required: {
                value: true,
                message: 'Password is required',
              },
              minLength: {
                value: 6,
                message: 'Too short password',
              },
              maxLength: {
                value: 50,
                message: 'Too long password',
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <PasswordInput
                placeholder="Enter password here"
                onChange={(event) => onChange(event?.target?.value)}
                label={'Password'}
                value={value}
                ref={ref}
                error={errors.password}
              />
            )}
          />
          <Button type="submit" variant="outline-primary">
            <BoxArrowInRight className="button-icon" /> Log In
          </Button>
        </Form>
      </Stack>
    </Container>
  )
}

LoginPage.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
}

export default LoginPage
