import ListMenu from '../ListMenu'

import styles from './Mobile.module.css'

const MobileMenu = ({ lang, isOpened, onHideMenu }) => {
  const isActiveMenuClass = isOpened ? styles.openMenu : ''
  const showOverlayClass = isOpened ? styles.showOverlay : ''

  return (
    <>
      <div className={`${styles.innerMenu} ${isActiveMenuClass}`}>
        <ListMenu lang={lang} innerClassName={styles.innerMenuLinks} onAfterClick={onHideMenu} />
      </div>
      <div onClick={onHideMenu} className={`${styles.overlay} ${showOverlayClass}`} />
    </>
  )
}

export default MobileMenu
