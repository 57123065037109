import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { verify } from '../services/authService'
import { AUTH_TYPE } from '../utils/constants'
import Loader from '../components/Loader'

function VerifyPage() {
  const { userId, code } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    verify(userId, code)
      .then(() => navigate(`/phone-verify/${AUTH_TYPE.EMAIL}/${userId}`))
      .catch(() => navigate('/verify-fail'))
  }, [])

  return <Loader />
}

export default VerifyPage
