import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'

import styles from '../ListMenu.module.css'

const MenuNavigationItem = ({ link, name, onClickMenuItem }) => {
  const { hash } = useLocation()

  const isActive = link === hash
  const activeClass = isActive ? styles.activeItemMenuLink : ''

  return (
    <li className={`${styles.itemMenuList} ${activeClass}`}>
      <Link onClick={onClickMenuItem} className={styles.itemMenuLink} to={link}>
        {name}
      </Link>
    </li>
  )
}

export default MenuNavigationItem
