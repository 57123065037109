const CloudSecond = ({ customClassName = '', width = '217', height = '100' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 217 100"
      className={customClassName}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M200.543 68.1554C197.65 68.1554 194.955 68.9329 192.604 70.2892C190.688 57.9926 180.073 48.5712 167.234 48.5712C162.713 48.5712 158.463 49.7467 154.774 51.7901C150.941 42.4229 141.736 35.8226 130.995 35.8226C125.877 35.8226 121.139 37.3235 117.143 39.9094C110.633 24.0685 95.0454 12.9111 76.8537 12.9111C55.0272 12.9111 36.9621 28.9509 33.8156 49.8913C31.2659 49.0414 28.5534 48.5712 25.7143 48.5712C11.519 48.5712 0 60.0902 0 74.2856C0 88.4809 11.519 99.9999 25.7143 99.9999H200.543C209.331 99.9999 216.456 92.8751 216.456 84.0867C216.456 75.2982 209.331 68.1554 200.543 68.1554Z"
        fill="#EBF0F8"
      />
      <path
        d="M200.832 12.6221C199.331 12.6221 197.903 12.9657 196.601 13.5443C195.172 5.84088 188.445 0 180.326 0C172.062 0 165.227 6.07596 163.979 13.9964C162.478 13.1284 160.724 12.6221 158.861 12.6221C153.201 12.6221 148.608 17.2152 148.608 22.8753C148.608 28.5353 153.201 33.1285 158.861 33.1285H200.814C206.474 33.1285 211.067 28.5353 211.067 22.8753C211.086 17.2152 206.492 12.6221 200.832 12.6221Z"
        fill="#F8FAFD"
      />
    </svg>
  )
}

export default CloudSecond
