import { Image } from 'react-bootstrap'

import styles from './index.module.css'

const Header = () => {
  return (
    <>
      <header className={styles.header}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <Image
              priority="true"
              width={114}
              height={46}
              alt="Logo"
              src="/images/positiveLogo.svg"
            />
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
