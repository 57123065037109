import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { Eye, EyeSlash } from 'react-bootstrap-icons'

const PasswordInput = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const { value, label, placeholder, error, onChange } = props

  return (
    <Form.Group className="m-3">
      <Form.Label as="h4">{label}</Form.Label>
      <InputGroup>
        <Form.Control
          value={value}
          ref={ref}
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          isInvalid={error}
        />
        <Button
          className="float-end"
          variant="outline-secondary"
          size="sm"
          onClick={handleClickShowPassword}
        >
          {showPassword ? <Eye /> : <EyeSlash />}
        </Button>
        <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  )
})

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PasswordInput
