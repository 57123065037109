import { ADMIN_ROUTES, LANGUAGE_UA, PUBLIC_ROUTES, STATIC_ROUTES } from './utils/constants'
import ChangePasswordPage from './pages/ChangePasswordPage'
import LoginPage from './pages/admin/LoginPage'
import UsersList from './pages/admin/User/List'
import VerifyPage from './pages/VerifyPage'
import GoogleAuthPage from './pages/GoogleAuthPage'
import GoogleAuthSuccessPage from './pages/GoogleAuthSuccessPage'
import PaymentPage from './pages/PaymentPage'
import PaymentsList from './pages/admin/PaymentsList'
import ScriptsList from './pages/admin/ScriptsList'
import UserDetails from './pages/admin/User/Details'
import PhoneVerifyPage from './pages/PhoneVerifyPage'
import TermsAndConditions from './pages/TermsAndConditions'

export const adminRoutes = [
  {
    path: ADMIN_ROUTES.USERS,
    Component: UsersList,
  },
  {
    path: ADMIN_ROUTES.USERS + '/:userId',
    Component: UserDetails,
  },
  {
    path: ADMIN_ROUTES.PAYMENTS,
    Component: PaymentsList,
  },
  {
    path: ADMIN_ROUTES.SCRIPTS,
    Component: ScriptsList,
  },
]

export const loginRoute = {
  path: ADMIN_ROUTES.LOGIN,
  Component: LoginPage,
}

export const publicRoutes = [
  { path: PUBLIC_ROUTES.TERMS_AND_CONDITIONS, Component: TermsAndConditions },
  {
    path: PUBLIC_ROUTES.TERMS_AND_CONDITIONS + '/ua',
    Component: TermsAndConditions,
    lang: LANGUAGE_UA,
  },
  {
    path: PUBLIC_ROUTES.CHANGE_PASSWORD + '/:userId/:code',
    Component: ChangePasswordPage,
  },
  {
    path: PUBLIC_ROUTES.VERIFY + '/:userId/:code',
    Component: VerifyPage,
  },
  {
    path: PUBLIC_ROUTES.GOOGLE_AUTH,
    Component: GoogleAuthPage,
  },
  {
    path: PUBLIC_ROUTES.GOOGLE_AUTH_SUCCESS + '/:token',
    Component: GoogleAuthSuccessPage,
  },
  {
    path: PUBLIC_ROUTES.PAYMENT + '/:userId',
    Component: PaymentPage,
  },
  {
    path: PUBLIC_ROUTES.PHONE_VERIFY + '/:authType/:userId',
    Component: PhoneVerifyPage,
  },
]

export const staticRoutes = [
  {
    path: STATIC_ROUTES.CHANGE_PASSWORD_SUCCESS,
    title: 'Change Password Success',
    text: 'Your password was successfully changed. Please use your new password to enter the extension.',
  },
  {
    path: STATIC_ROUTES.CHANGE_PASSWORD_FAIL,
    title: 'Change Password Failed',
    text: 'User was not found or the code expired.',
  },
  {
    path: STATIC_ROUTES.VERIFY_SUCCESS + '/:token',
    title: 'Verification Success',
    text: 'You have been successfully verified. You have available 10 free cover letter generators.',
  },
  {
    path: STATIC_ROUTES.VERIFY_FAIL,
    title: 'Verification Failed',
    text: 'User was not found or the code expired.',
  },
  {
    path: STATIC_ROUTES.GOOGLE_AUTH_FAIL,
    title: 'Google Authorization Failed',
    text: 'Something went wrong with google authorization. Try again or use another authorization type.',
  },
  {
    path: STATIC_ROUTES.NOT_FOUND,
    title: 'Page not found',
    text: `We can't find this page. Maybe you were looking for something else`,
  },
  {
    path: STATIC_ROUTES.USER_BLOCKED,
    title: 'User is blocked',
    text: `If this is mistake, contact Support.`,
  },
  {
    path: STATIC_ROUTES.USER_ACTIVE,
    title: 'User is active',
    text: `You're already active. Use this account to enter the extension.`,
  },
  {
    path: STATIC_ROUTES.USER_NOT_VERIFIED,
    title: 'User is not verified',
    text: `Please, verify your email first. If you've not received the letter, contact Support`,
  },
]
