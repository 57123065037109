import axios from '../axios'

export const checkRestorePassword = async (userId, code) => {
  return await axios.get(`/user/restore-check/${userId}/${code}`)
}

export const restorePassword = async (userId, code, values) => {
  return await axios.put(`/user/restore/${userId}/${code}`, values)
}

export const getStatus = async (userId) => {
  return await axios.get(`/user/status/${userId}`)
}
