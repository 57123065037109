import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, CloseButton, Form, InputGroup } from 'react-bootstrap'
import { PencilSquare, PlusCircle } from 'react-bootstrap-icons'

import { STATIC_FIELDS } from '../../../../utils/constants'
import UpsertFieldModal from './Upsert'
import styles from './index.module.css'

function FieldsForm({ setError, setValue, textArea, fields, setFields, clearErrors }) {
  const [modal, setModal] = useState(null)

  const insertField = (event) => {
    const currentPosition = textArea.current.selectionStart

    const value = textArea.current.value

    clearErrors('value')
    setValue(
      'value',
      `${value.slice(0, currentPosition)}{${event.currentTarget.textContent}}${value.slice(
        currentPosition
      )}`
    )
    setError()
  }

  const deleteField = (event) => {
    const fieldText =
      event.currentTarget?.parentNode?.getElementsByClassName('field-group__item')[0]?.textContent
    if (fieldText) {
      const value = textArea.current.value
      setValue('value', value.replaceAll(`{${fieldText}}`, ''))

      const filteredFields = fields.filter((e) => e.name !== fieldText)
      setFields(filteredFields)
    }
  }

  const showCreateModal = () => {
    setModal(<UpsertFieldModal setModal={setModal} setFields={setFields} fields={fields} />)
  }

  const showUpdateModal = (field) => {
    setModal(
      <UpsertFieldModal
        setModal={setModal}
        setFields={setFields}
        fields={fields}
        field={field}
        textArea={textArea}
        setScriptValue={setValue}
      />
    )
  }

  return (
    <>
      {modal}
      <Form.Label>Fields</Form.Label>
      <Form.Group className="field-group">
        {STATIC_FIELDS.map((field, key) => (
          <Badge key={key} bg className={styles.staticBadge} text="dark">
            <InputGroup>
              <div className="field-group__item" onClick={insertField}>
                {field}
              </div>
              <CloseButton style={{ marginLeft: 4 }} onClick={deleteField} />
            </InputGroup>
          </Badge>
        ))}
        {fields.map((field, key) => (
          <Badge key={key} bg className={styles.dynamicBadge} text="light">
            <InputGroup>
              <PencilSquare
                className={styles.badgeEdit}
                size={15}
                onClick={() => showUpdateModal(field)}
              />
              <div className="field-group__item" onClick={insertField}>
                {`${field.name}`}
              </div>
              <CloseButton style={{ marginLeft: 4 }} onClick={deleteField} />
            </InputGroup>
          </Badge>
        ))}
        <Badge bg className={styles.addBadge} text="light" onClick={showCreateModal}>
          <PlusCircle className="button-icon" style={{ margin: 'auto' }} />
        </Badge>
      </Form.Group>
    </>
  )
}

FieldsForm.propTypes = {
  setError: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  textArea: PropTypes.shape({
    current: PropTypes.shape({
      selectionStart: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
    }),
  }),
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

FieldsForm.defaultProps = {
  isLoading: false,
}

export default FieldsForm
