import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { PersonFillX, XCircle } from 'react-bootstrap-icons'

import { blockUser } from '../../../services/admin/adminService'

const BlockConfirmModal = ({ setModal, setUserUpdated, userId, setIsLoading }) => {
  const closeModal = () => setModal(null)

  const handleBlockUser = async () => {
    setIsLoading(true)
    await blockUser(userId)
    setUserUpdated(true)
    closeModal()
  }

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header>Are you sure to block this user?</Modal.Header>
      <Modal.Footer>
        <Button
          style={{ position: 'absolute', left: '13px' }}
          variant="outline-danger"
          onClick={() => handleBlockUser()}
        >
          <PersonFillX className="button-icon" /> Block
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          <XCircle className="button-icon" /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

BlockConfirmModal.propTypes = {
  setModal: PropTypes.func.isRequired,
  setUserUpdated: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
}

export default BlockConfirmModal
