import React from 'react'
import PropTypes from 'prop-types'

function StaticPageLayout({ title, text }) {
  return (
    <section>
      <h1>{title}</h1>
      <p>{text}</p>
    </section>
  )
}

StaticPageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default StaticPageLayout
