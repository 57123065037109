import { Image } from 'react-bootstrap'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  CloudFirst,
  CloudFourth,
  CloudSecond,
  CloudThird,
  DoYouCloud,
} from '../components/Home/SvgIcon'
import Layout from '../components/Home/Layout'
import Button from '../components/Home/Button'
import './home.css'
import YouWillItem from '../components/Home/YouWillItem'
import DoYouItem from '../components/Home/DoYouItem'
import {
  HOME_DO_YOU_ITEMS,
  HOME_DO_YOU_ITEMS_UA,
  HOME_YOU_WILL_ITEMS,
  HOME_YOU_WILL_ITEMS_UA,
  LANGUAGE_UA,
} from '../utils/constants'
import HowItWorks from '../components/Home/HowItWorks'
import GenerationsExample from '../components/Home/GenerationsExample'
import Section from '../components/Home/Section'
import Pricing from '../components/Home/Pricing'

const Home = ({ lang }) => {
  return (
    <Layout lang={lang}>
      <Section customClassName="homeHead" path="home">
        <div className="container d-flex flex-column align-items-center max-w-1067 position-relative overflow-clip">
          {lang === LANGUAGE_UA ? (
            <>
              <h1 className="homeHeadTitle">
                Створюй унікальні листи на <span>UpWork в 10 разів ШВИДШЕ</span> за допомогою{' '}
                <span>ШІ</span>
              </h1>
              <p className="supHomeHeadTitleText">
                Pinta.AI створює індивідуальні супроводжувальні листи для Upwork, аналізуючи описи
                проектів та інформацію про профіль.
              </p>
            </>
          ) : (
            <>
              <h1 className="homeHeadTitle">
                Create custom <span>UpWork</span> proposals <span>10x FASTER</span> using{' '}
                <span>AI</span>
              </h1>
              <p className="supHomeHeadTitleText">
                Pinta.AI generates tailored UpWork proposals by analyzing project descriptions and
                profile information to select the most suitable expertise for the job.
              </p>
            </>
          )}

          <div className="d-flex align-items-center gap-3 lg:gap-8 mb-10 lg:mb-0">
            {lang !== LANGUAGE_UA ? (
              <Button isNegativeButton customClassName="homeHeadButton">
                Get started
              </Button>
            ) : null}
          </div>
          <div className="position-relative w-100">
            <Image className="homeHeadImage" src="/images/cybernaut.png" alt="Cybernaut" />
            <div>
              <CloudFirst customClassName="homeHeadCloud" />
              <CloudSecond customClassName="homeHeadCloud" />
              <CloudThird customClassName="homeHeadCloud" />
              <CloudFourth customClassName="homeHeadCloud" />
            </div>
          </div>
        </div>
      </Section>
      <Section customClassName="overflow-clip" path="possibilities">
        <div className="container position-relative">
          <div className="youWillBlock">
            {lang === LANGUAGE_UA ? (
              <>
                <h2 className="blockTitle">
                  З <span>Pinta.AI</span> ти зможеш:
                </h2>
                <div className="youWillItems">
                  {HOME_YOU_WILL_ITEMS_UA.map(({ Icon, title, text }) => (
                    <YouWillItem key={title} Icon={Icon} title={title} text={text} />
                  ))}
                </div>
              </>
            ) : (
              <>
                <h2 className="blockTitle">
                  With <span>Pinta.AI</span> you will:
                </h2>
                <div className="youWillItems">
                  {HOME_YOU_WILL_ITEMS.map(({ Icon, title, text }) => (
                    <YouWillItem key={title} Icon={Icon} title={title} text={text} />
                  ))}
                </div>
              </>
            )}
          </div>
          <Image className="youWillImage" src="/images/youWill.png" alt="You Will" />
        </div>
      </Section>
      <Section customClassName="overflow-clip" path="examples">
        <div className="container position-relative exampleContainer">
          {lang === LANGUAGE_UA ? (
            <>
              <h2 className="blockTitle examplesTitle">
                Приклади <span>згенерованого</span> тексту
              </h2>
              <p>Переглянь різні приклади згенерованих ШІ текстів, що поділені за скриптами</p>
            </>
          ) : (
            <>
              <h2 className="blockTitle examplesTitle">
                Generation <span>Examples</span>
              </h2>
              <p>Please review generation examples for different script types</p>
            </>
          )}

          <GenerationsExample lang={lang} />
        </div>
      </Section>
      <Section customClassName="doYouBlock overflow-clip" path="doYou">
        <div className="container doYouContainer">
          <DoYouCloud customClassName="doYouCloud" />
          <Image className="doYouImage" src="/images/doYou.png" alt="Do You" />
          <div className="doYouContent">
            {lang === LANGUAGE_UA ? (
              <h2 className="doYouTitle">Чи бувало що ти….? </h2>
            ) : (
              <h2 className="doYouTitle">Do You ...?</h2>
            )}
            <div className="doYouItems">
              {lang === LANGUAGE_UA
                ? HOME_DO_YOU_ITEMS_UA.map(({ Icon, title, text }) => (
                    <DoYouItem key={title} Icon={Icon} title={title} text={text} />
                  ))
                : HOME_DO_YOU_ITEMS.map(({ Icon, title, text }) => (
                    <DoYouItem key={title} Icon={Icon} title={title} text={text} />
                  ))}
            </div>
          </div>
        </div>
      </Section>
      <Section customClassName="overflow-clip" path="howItWorks">
        <HowItWorks lang={lang} />
      </Section>
      {lang === LANGUAGE_UA && (
        <>
          <Section path="aboutUs" customClassName="overflow-clip">
            <div className="container">
              <div className="aboutUsBlock position-relative">
                <div className="aboutUsContent">
                  <div className="aboutUsCard">
                    <h2 className="blockTitle">
                      Про <span>Нас</span>
                    </h2>
                    <p className="aboutUsText">
                      ФОП Калита Олег Дмитрович,
                      <br /> ЄДРПОУ 3234923659
                      <br /> м. Хмельницький, вул М. Трембовецької 23. <br />
                      +380676628836
                      <br />
                      oleg.kalyta@gmail.com
                    </p>
                  </div>
                  <div className="aboutUsCard">
                    <h2 className="blockTitle">
                      Умови <span>Повернення</span>
                    </h2>
                    <p className="aboutUsText">
                      Користувач отримує 10 безкоштовних генерацій супроводжувальних листів.
                      Вартість невикористаних генерацій може бути повернена користувачу.
                      <br />
                      Для цього - напишіть на oleg.kalyta@gmail.com з темою листа Повернення коштів.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Pricing />
          </Section>
        </>
      )}
    </Layout>
  )
}

export default Home
