import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Section = ({ path, customClassName, children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const el = document.getElementById(path)
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          navigate(`#${path}`)
          return
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
    )

    observer.observe(el)
  }, [path, navigate])

  return (
    <section className={customClassName} id={path}>
      {children}
    </section>
  )
}

export default Section
