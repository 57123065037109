const ArrowRight = ({ customClassName }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M12.5248 4.31657C12.6831 4.31657 12.8415 4.3749 12.9665 4.4999L18.0248 9.55824C18.2665 9.7999 18.2665 10.1999 18.0248 10.4416L12.9665 15.4999C12.7248 15.7416 12.3248 15.7416 12.0831 15.4999C11.8415 15.2582 11.8415 14.8582 12.0831 14.6166L16.6998 9.9999L12.0831 5.38324C11.8415 5.14157 11.8415 4.74157 12.0831 4.4999C12.1998 4.3749 12.3665 4.31657 12.5248 4.31657Z"
        fill="#4945FF"
      />
      <path
        d="M3.41689 9.375L17.4419 9.375C17.7836 9.375 18.0669 9.65833 18.0669 10C18.0669 10.3417 17.7836 10.625 17.4419 10.625L3.41689 10.625C3.07523 10.625 2.79189 10.3417 2.79189 10C2.79189 9.65833 3.07523 9.375 3.41689 9.375Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default ArrowRight
