import { Image } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'

import {
  FOOTER_NAVIGATION,
  FOOTER_NAVIGATION_UA,
  FOOTER_SOCIAL,
  LANGUAGE_UA,
  PUBLIC_ROUTES,
} from '../../../utils/constants'
import styles from './Footer.module.css'

const Footer = ({ lang }) => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <Image
            width={114}
            height={46}
            className={styles.footerLogo}
            alt="Logo"
            src="/images/negativeLogo.svg"
          />
          <div className={styles.innerFooterNavigation}>
            {lang === LANGUAGE_UA
              ? FOOTER_NAVIGATION_UA.map(({ name, link }) => (
                  <Link className={styles.navigateFooterItem} key={link} to={link}>
                    {name}
                  </Link>
                ))
              : FOOTER_NAVIGATION.map(({ name, link }) => (
                  <Link className={styles.navigateFooterItem} key={link} to={link}>
                    {name}
                  </Link>
                ))}
          </div>
          <div className={`w-100 d-flex align-items-center ${styles.footerContent}`}>
            {lang === LANGUAGE_UA ? (
              <div />
            ) : (
              <div className={styles.socials}>
                {FOOTER_SOCIAL.map(({ SocialIcon, link }) => (
                  <a
                    key={link}
                    rel="noreferrer"
                    target="_blank"
                    className={styles.socialItem}
                    href={link}
                  >
                    <SocialIcon />
                  </a>
                ))}
              </div>
            )}

            <p className={styles.footerCopyright}>Copyrighted © 2023 Pinta.AI</p>
            <a
              href={`${PUBLIC_ROUTES.TERMS_AND_CONDITIONS}${lang === LANGUAGE_UA ? '/ua' : ''}`}
              className={styles.footerTermsConditions}
            >
              {lang === LANGUAGE_UA ? 'Положення та Умови' : 'Terms & Conditions'}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
