const CloudThird = ({ customClassName = '', width = '135', height = '60' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 135 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M135 42.2841C135 32.5042 127.126 24.5681 117.422 24.5681C113.985 24.5681 110.784 25.5772 108.077 27.2965C107.384 22.0266 102.922 17.9651 97.5076 17.9651C94.1082 17.9651 91.0796 19.5723 89.1265 22.0764C85.6776 9.35631 74.132 0 60.423 0C50.3113 0 41.3863 5.08307 36.0091 12.8572C34.1301 11.2126 31.6825 10.2035 29.0001 10.2035C23.1036 10.2035 18.3321 15.0249 18.3321 20.9552C18.3321 22.2508 18.567 23.4842 18.9873 24.6429C18.5175 24.6055 18.0602 24.5681 17.5781 24.5681C7.87428 24.5681 0 32.5042 0 42.2841C0 52.064 7.87428 60 17.5781 60H117.904V59.9751C127.385 59.7135 135 51.902 135 42.2841Z"
        fill="#EBF0F8"
      />
    </svg>
  )
}

export default CloudThird
