const ArrowLeft = ({ customClassName }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={customClassName}
    >
      <path
        d="M8.4752 15.6834C8.31686 15.6834 8.15853 15.6251 8.03353 15.5001L2.9752 10.4418C2.73353 10.2001 2.73353 9.8001 2.9752 9.55843L8.03353 4.5001C8.2752 4.25843 8.6752 4.25843 8.91686 4.5001C9.15853 4.74176 9.15853 5.14176 8.91686 5.38343L4.3002 10.0001L8.91686 14.6168C9.15853 14.8584 9.15853 15.2584 8.91686 15.5001C8.8002 15.6251 8.63353 15.6834 8.4752 15.6834Z"
        fill="#4945FF"
      />
      <path
        d="M17.5831 10.625H3.55811C3.21644 10.625 2.93311 10.3417 2.93311 10C2.93311 9.65833 3.21644 9.375 3.55811 9.375H17.5831C17.9248 9.375 18.2081 9.65833 18.2081 10C18.2081 10.3417 17.9248 10.625 17.5831 10.625Z"
        fill="#4945FF"
      />
    </svg>
  )
}

export default ArrowLeft
