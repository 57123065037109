import axios from '../../axios'

export const login = async (values) => {
  return await axios.post('/admin/login', values)
}

export const getAllUsersPaginated = async (currentPage, itemsPerPage) => {
  return axios.get(`/admin/user/all?page=${currentPage}&size=${itemsPerPage}`)
}

export const blockUser = async (userId) => {
  await axios.put(`/admin/user/block/${userId}`)
}

export const getUserById = async (userId) => {
  return await axios.get(`/admin/user/${userId}`)
}

export const getAllUserPayments = async (userId, currentPage, itemsPerPage) => {
  return axios.get(`/payment/all/${userId}?page=${currentPage}&size=${itemsPerPage}`)
}

export const getAllPayments = async (currentPage, itemsPerPage) => {
  return axios.get(`/payment/all?page=${currentPage}&size=${itemsPerPage}`)
}
