import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Loader from '../components/Loader'
import { verifyToken } from '../services/authService'

function GoogleAuthSuccessPage() {
  const { token } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    verifyToken(token)
      .then(() => setIsLoading(false))
      .catch(() => navigate('/google-auth-fail'))
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <section>
      <h1>Google Authorization Success</h1>
      <p>
        You have been successfully signed in. Please reload the extension page to start using it.
      </p>
    </section>
  )
}

export default GoogleAuthSuccessPage
