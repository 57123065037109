import React, { useState, useEffect } from 'react'
import { Button, Container, Row, Spinner, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { PersonFillX } from 'react-bootstrap-icons'

import { getAllUsersPaginated } from '../../../services/admin/adminService'
import Pagination from '../../../components/Pagination'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../utils/constants'
import BlockConfirmModal from '../../../components/admin/User/BlockConfirm'

function UsersList() {
  const [modal, setModal] = useState(null)
  const [userUpdated, setUserUpdated] = useState(false)
  const [users, setUsers] = useState([])
  const [usersAmount, setUsersAmount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getAllUsersPaginated(currentPage, DEFAULT_ITEMS_PER_PAGE)
      .then(({ data }) => {
        setUsers(data.users)
        setUsersAmount(data.totalAmount)
        setUserUpdated(false)
        setIsLoading(false)
      })
      .catch((error) => console.log(error))
  }, [currentPage, userUpdated])

  const handlePageChange = (page) => setCurrentPage(page)

  const showBlockModal = (userId) => {
    setModal(
      <BlockConfirmModal
        setModal={setModal}
        setUserUpdated={setUserUpdated}
        userId={userId}
        setIsLoading={setIsLoading}
      />
    )
  }
  return (
    <>
      {isLoading ? <Spinner animation={'border'} className="form-spinner" /> : null}
      {modal}
      <Container
        fluid="xxl"
        className="mt-5"
        style={isLoading ? { filter: `blur(3px)`, pointerEvents: 'none' } : {}}
      >
        <Row>
          <h1>Users</h1>
        </Row>
        <Table bordered hover responsive="sm" className="mt-5">
          <thead>
            <tr>
              <th>Email</th>
              <th>User Status</th>
              <th>Billing Status</th>
              <th>Id</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td className="clickable-item" onClick={() => navigate(`/admin/users/${user._id}`)}>
                  {user.email}
                </td>
                <td className="clickable-item" onClick={() => navigate(`/admin/users/${user._id}`)}>
                  {user.userStatus}
                </td>
                <td className="clickable-item" onClick={() => navigate(`/admin/users/${user._id}`)}>
                  {user.billingStatus}
                </td>
                <td className="clickable-item" onClick={() => navigate(`/admin/users/${user._id}`)}>
                  {user._id}
                </td>
                <td>
                  {user.userStatus !== 'Blocked' ? (
                    <Button variant="outline-danger" onClick={() => showBlockModal(user._id)}>
                      <PersonFillX className="button-icon" /> Block
                    </Button>
                  ) : (
                    <Button variant="secondary" disabled>
                      Blocked
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={DEFAULT_ITEMS_PER_PAGE}
          totalItems={usersAmount}
          onPageChange={handlePageChange}
        />
      </Container>
    </>
  )
}

export default UsersList
