import { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import AdminLayout from './components/Layouts/AdminLayout'
import Layout from './components/Layouts/PublicLayout'
import StaticPageLayout from './components/Layouts/StaticPageLayout'
import Home from './pages/Home'
import { adminRoutes, publicRoutes, loginRoute, staticRoutes } from './routes'
import { LANGUAGE_UA } from './utils/constants'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('jwtToken'))

  return (
    <div className="App">
      <Routes>
        <Route
          path="admin"
          element={<AdminLayout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}
          exact
        >
          <Route
            path={loginRoute.path}
            element={
              isLoggedIn ? (
                <Navigate to="/admin/users" />
              ) : (
                <loginRoute.Component setIsLoggedIn={setIsLoggedIn} />
              )
            }
          />
          <Route path="/admin*" element={<Navigate to="/admin/users" />} />
          <Route path="/admin" element={<Navigate to="/admin/users" />} />
          {adminRoutes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={isLoggedIn ? <Component /> : <Navigate to="/admin/login" />}
            />
          ))}
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/ua" element={<Home lang={LANGUAGE_UA} />} />
        <Route path="/" element={<Layout />}>
          {publicRoutes.map(({ path, Component, lang }) => (
            <Route key={path} path={path} element={<Component lang={lang} />} />
          ))}
          {staticRoutes.map(({ path, title, text }) => (
            <Route
              key={path}
              path={path}
              element={<StaticPageLayout title={title} text={text} />}
            />
          ))}
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
