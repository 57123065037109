const CloudFirst = ({ customClassName = '', width = '228', height = '73' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 228 73"
      fill="none"
      className={customClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M186.7 52.4055C184.5 52.4055 182.471 53.0901 180.8 54.2596C180.857 53.6464 180.9 53.0331 180.9 52.4055C180.9 41.2098 171.8 32.1248 160.586 32.1248C155.629 32.1248 151.086 33.9076 147.557 36.8599C146.643 33.7222 143.743 31.426 140.3 31.426C139.014 31.426 137.8 31.754 136.743 32.3103C136.886 31.5829 136.957 30.827 136.957 30.0569C136.957 23.2539 131.429 17.7487 124.629 17.7487C120.014 17.7487 116.014 20.2731 113.9 24.0098C108.457 12.2007 96.5143 4 82.6429 4C64.2714 4 49.2714 18.3762 48.3 36.4748C44.8428 33.765 40.5 32.1248 35.7571 32.1248C24.5429 32.1248 15.4429 41.2098 15.4429 52.4055C15.4429 52.8904 15.4857 53.3469 15.5143 53.8175C13.9857 52.919 12.2143 52.4055 10.3143 52.4055C4.61429 52.4055 0 57.0122 0 62.7028C0 68.3933 4.61429 73 10.3143 73H186.686C192.386 73 197 68.3933 197 62.7028C197.014 57.0122 192.4 52.4055 186.7 52.4055Z"
        fill="#EBF0F8"
      />
      <path
        d="M221.029 10.952C219.132 10.952 217.422 11.7188 216.157 12.9485C216.157 12.7894 216.186 12.6447 216.186 12.4855C216.186 5.5845 210.624 0 203.782 0C198.536 0 194.066 3.28415 192.241 7.91378C191.594 7.42188 190.804 7.11808 189.941 7.11808C187.829 7.11808 186.118 8.83972 186.118 10.9665C186.118 11.2413 186.147 11.5162 186.205 11.7766C185.242 11.2703 184.135 10.9665 182.971 10.9665C179.119 10.9665 176 14.1059 176 17.9832C176 21.8605 179.119 25 182.971 25H221.029C224.881 25 228 21.8605 228 17.9832C228 14.0914 224.881 10.952 221.029 10.952Z"
        fill="#F8FAFD"
      />
    </svg>
  )
}

export default CloudFirst
