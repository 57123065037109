import React from 'react'
import style from './DoYouItem.module.css'

function DoYouItem({ Icon, title, text }) {
  return (
    <div className={style.block}>
      <div className={style.titleBlock}>
        <div className={style.iconBlock}>
          <Icon customClassName={style.icon} />
        </div>
        <h3 className={style.title}>{title}</h3>
      </div>

      <p>{text}</p>
    </div>
  )
}

DoYouItem.propTypes = {}

export default DoYouItem
