import { LANGUAGE_UA, MENU_NAVIGATION, MENU_NAVIGATION_UA } from '../../../utils/constants'
import MenuNavigationItem from './components/MenuNavigationItem'
import styles from './ListMenu.module.css'

const ListMenu = ({ lang, innerClassName = '', onAfterClick = () => {} }) => {
  return (
    <ul className={`${styles.innerMenuList} ${innerClassName}`}>
      {lang === LANGUAGE_UA
        ? MENU_NAVIGATION_UA.map(({ name, link }) => (
            <MenuNavigationItem key={link} name={name} link={link} onClickMenuItem={onAfterClick} />
          ))
        : MENU_NAVIGATION.map(({ name, link }) => (
            <MenuNavigationItem key={link} name={name} link={link} onClickMenuItem={onAfterClick} />
          ))}
    </ul>
  )
}

export default ListMenu
